<template>
  <div
    class="modal fade"
    data-backdrop="static"
    data-keyboard="false"
    ref="modal"
  >
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            :disabled="isDismissButtonDisabled"
          >
            &times;
          </button>

          <h4 class="modal-title">
            <i class="fa fa-globe"></i>
            <template v-if="isUpdating"> Update {{ entityLabel }} </template>
            <template v-else> Create {{ entityLabel }} </template>
          </h4>
        </div>

        <div class="modal-body">
          <slot name="modalBody"></slot>
        </div>

        <div class="modal-footer">
          <button
            type="button"
            v-if="isActivatePromotion && isUpdating"
            style="float: left"
            class="btn button-pr"
            data-dismiss="modal"
            @click="onActivate"
            :disabled="isPromoActivated"
          >
            Activate Promotion
          </button>
          <button
            type="button"
            class="btn button-sec"
            data-dismiss="modal"
            :disabled="isDismissButtonDisabled"
          >
            Cancel
          </button>

          <button
            type="button"
            v-if="isPromo && !isPromoActivated"
            class="btn button-sec"
            @click="onSaveAsDraft"
          >
            <template v-if="isDraftUpdating || isDealerPromo">
              UpdateAsDraft
            </template>
            <template v-else> SaveAsDraft </template>
          </button>

          <button
            type="button"
            class="btn button-pr"
            :disabled="
              isButtonDisable || isSaveButtonDisabled || isDismissButtonDisabled
            "
            @click="onSave"
          >
            <template v-if="isUpdating"> Update {{ entityLabel }} </template>
            <template v-else> Create {{ entityLabel }} </template>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { $ } from "@/assets/vendor";
import { mapGetters } from "vuex";

export default {
  name: "EntityEditModal",
  props: {
    promotionValue: {
      type: Object,
    },
    entityLabel: {
      required: false,
      type: String,
      default: () => "Entity",
    },
    isDismissButtonDisabled: {
      type: Boolean,
    },
    isSaveButtonDisabled: {
      type: Boolean,
    },
    isUpdating: {
      type: Boolean,
    },
    isDraftUpdating: {
      type: Boolean,
    },
    isDealerPromo: {
      type: Boolean,
    },
    isActivatePromotion: {
      type: Boolean,
    },
    isPromo: {
      type: Boolean,
    },
    isPromoActivated: {
      type: Boolean,
    },
    isButtonDisable: {
      type: Boolean,
    },
  },
  data() {
    return {
      mutubale: this.promotionValue,
    };
  },

  created() {
    this.$eventBus.$on("openEntityModal", () => {
      $(this.$refs.modal).modal("show");
    });

    this.$eventBus.$on("hideEntityModal", () => {
      $(this.$refs.modal).modal("hide");
      this.$emit("dismissed");
    });
  },

  methods: {
    async onSave() {
      this.$emit("save-clicked");
    },
    onActivate() {
      this.$emit("activate-clicked");
    },
    onSaveAsDraft() {
      this.$emit("save-draft-clicked");
    },
  },
  computed: {
    ...mapGetters("promotions", ["promotions", "tableHeaders"]),
  },
};
</script>
<style scoped lang="scss">
.button-pr {
  background-color: #16171a !important;
  border-color: #16171a !important;
  color: #fff !important;
  border-radius: 0 !important;
  font-weight: 700 !important;
  opacity: 1 !important;
}

.button-sec {
  background-color: #fff !important;
  border-color: #16171a !important;
  color: #16171a !important;
  border-radius: 0 !important;
  font-weight: 700 !important;
  opacity: 1 !important;
}

.button-sec:hover,
.button-pr:hover {
  background-color: #0062ff !important;
  border-color: #0062ff !important;
  color: #fff !important;
  text-decoration: none;
}

.btn.button-pr:disabled {
  opacity: 0.5 !important;
  cursor: not-allowed;
}
</style>
