import { reactive } from "vue";
import { PromitionsApi } from "@/server";
import { clone } from "@/util";

const initialState = {
  promotions: [],
  tableHeaders: [],
  promotionsValues: [],
  availableRentalPartners: [],
  availableDealers: [],
};
export default {
  namespaced: true,
  state: reactive(initialState),
  getters: {
    promotions: (state) => state.promotions,
    tableHeaders: (state) => state.tableHeaders,
    promotionsValues: (state) => state.promotionsValues,
    availableRentalPartners: (state) => state.availableRentalPartners,
    availableDealers: (state) => state.availableDealers,
  },
  actions: {
    async getPromotions({ commit }) {
      const { data } = await PromitionsApi.getPromotions();
      commit("SET_PROMOTIONS", data);
    },
    async getPromotionsValues({ commit }) {
      const { data } = await PromitionsApi.getPromotionValues();
      commit("SET_PROMOTIONS_VALUES", data);
    },
    async createPromotion({ commit }, promotion) {
      // Handling special case for M1000R
      for (let i = 0; i < promotion.promotionVehicles.length; i++) {
        const vehicle = promotion.promotionVehicles[i];
        if (
          vehicle.name === "M 1000 R(EMEA)" ||
          vehicle.name === "M 1000 R(USA)"
        ) {
          promotion.promotionVehicles[i].name = "M 1000 R";
        }
      }
      const { data } = await PromitionsApi.createUser(promotion);
      commit("ADD_PROMOTION", data);
    },
    async createDraftPromotion({ commit }, promotion) {
      const { data } = await PromitionsApi.createDraft(promotion);
      commit("ADD_DRAFT_PROMOTION", data);
    },
    async updateDraftPromotion({ commit }, promotion) {
      const { data } = await PromitionsApi.updateDraft(promotion.id, promotion);
      commit("UPDATE_PROMOTION", data);
    },
    async updatePromotion({ commit }, promotion) {
      // Handling special case for M1000R
      for (let i = 0; i < promotion.promotionVehicles.length; i++) {
        const vehicle = promotion.promotionVehicles[i];
        if (
          vehicle.name === "M 1000 R(EMEA)" ||
          vehicle.name === "M 1000 R(USA)"
        ) {
          promotion.promotionVehicles[i].name = "M 1000 R";
        }
      }
      const { data } = await PromitionsApi.updatePromotion(
        promotion.id,
        promotion
      );
      commit("UPDATE_PROMOTION", data);
    },
    async deletePromotion({ commit }, promoId) {
      await PromitionsApi.deletePromotion(promoId);
      commit("DELETE_PROMOTION", promoId);
    },
    async copyPromotion({ commit }, promoId) {
      // Handling special case for M1000R
      const { data } = await PromitionsApi.copyPromotion(promoId);
      for (let i = 0; i < data.promotionVehicles.length; i++) {
        const vehicle = data.promotionVehicles[i];
        if (vehicle.vehicleModelId === "d152faef-87f6-4e37-8fbd-55341a1dd324") {
          data.promotionVehicles[i].name = "M 1000 R(EMEA)";
        } else if (
          vehicle.vehicleModelId === "de7e5021-18ce-43df-868a-824090ec4549"
        ) {
          data.promotionVehicles[i].name = "M 1000 R(USA)";
        }
      }
      commit("COPY_PROMOTION", data);
    },
    async activatePromotion({ commit }, promoId) {
      // Handling special case for M1000R
      const { data } = await PromitionsApi.activatePromotion(promoId);
      for (let i = 0; i < data.promotionVehicles.length; i++) {
        const vehicle = data.promotionVehicles[i];
        if (vehicle.vehicleModelId === "d152faef-87f6-4e37-8fbd-55341a1dd324") {
          data.promotionVehicles[i].name = "M 1000 R(EMEA)";
        } else if (
          vehicle.vehicleModelId === "de7e5021-18ce-43df-868a-824090ec4549"
        ) {
          data.promotionVehicles[i].name = "M 1000 R(USA)";
        }
      }
      commit("UPDATE_PROMOTION", data);
    },
    async getAvailableRentalPartners({ commit }, id, promoId) {
      const { data } = await PromitionsApi.getAvailableRentalPartners(id);
      let dealerMap = {};
      if (promoId == null || promoId == undefined) {
        dealerMap.default = data;
      } else {
        dealerMap[promoId] = data;
      }
      commit("SET_AVAILABLE_RENTAL_PARTNERS", dealerMap);
    },
    async getPromotionDetails({ commit }, promoId) {
      const { data } = await PromitionsApi.getPromotionDetails(promoId);
      for (let i = 0; i < data.promotionVehicles.length; i++) {
        const vehicle = data.promotionVehicles[i];
        if (vehicle.vehicleModelId === "d152faef-87f6-4e37-8fbd-55341a1dd324") {
          data.promotionVehicles[i].name = "M 1000 R(EMEA)";
        } else if (
          vehicle.vehicleModelId === "de7e5021-18ce-43df-868a-824090ec4549"
        ) {
          data.promotionVehicles[i].name = "M 1000 R(USA)";
        }
      }
      commit("SET_PROMOTION_DATA", data);
    },
  },
  mutations: {
    SET_PROMOTIONS(state, data) {
      // data = migratePromotions(data);
      state.promotions = data;
    },
    SET_PROMOTIONS_VALUES(state, data) {
      state.promotionsValues = data;
    },
    ADD_PROMOTION(state, data) {
      state.promotions.push(data);
    },
    ADD_DRAFT_PROMOTION(state, data) {
      state.promotions.push(data);
    },
    UPDATE_PROMOTION(state, data) {
      const indexToUpdate = state.promotions.findIndex(
        (entry) => entry.promoId === data.promoId
      );
      if (indexToUpdate >= 0) {
        state.promotions[indexToUpdate] = data;
      }
    },
    DELETE_PROMOTION(state, promoId) {
      const index = state.promotions.findIndex(
        (entry) => entry.promoId === promoId
      );
      if (index >= 0) {
        state.promotions.splice(index, 1);
      }
    },
    COPY_PROMOTION(state, data) {
      state.promotions.push(data);
    },
    SET_AVAILABLE_RENTAL_PARTNERS(state, data) {
      state.availableRentalPartners = data;
      state.availableDealers = data;
    },
    SET_PROMOTION_DATA(state, data) {
      const indexToUpdate = state.promotions.findIndex(
        (entry) => entry.promoId === data.promoId
      );
      if (indexToUpdate >= 0) {
        state.promotions[indexToUpdate] = data;
      }
    },
  },
};

/**
 * Update promotions adding missing properties that did not exist during their
 * initial creation (e.g. translations for new languages).
 */
function migratePromotions(promotions) {
  const plainPromotion = {};

  return promotions.map((p) => addMissingLanguages(p, plainPromotion));
}

function addMissingLanguages(promotion, plainPromotion) {
  const updatedPromotion = clone(promotion);

  // Clone the factory's
  updatedPromotion.promoDescriptions = clone(plainPromotion.promoDescriptions);

  if (
    updatedPromotion.promoDescriptions != null &&
    promotion.promoDescriptions !== undefined
  ) {
    updatedPromotion.promoDescriptions = updatedPromotion.promoDescriptions.map(
      (description) => {
        if (
          !promotion.promoDescriptions
            .map((p) => p.langIsoAlpha2)
            .includes(description.langIsoAlpha2)
        )
          return description;
        return promotion.promoDescriptions.find(
          (p) => p.langIsoAlpha2 === description.langIsoAlpha2
        );
      }
    );
  }

  return updatedPromotion;
}
