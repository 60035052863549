export const LANGUAGE_CODES = {
    de: 'DE',
    en: 'EN',
    fr: 'FR',
    it: 'IT',
    sr: 'RS',
    sk: 'SK',
    nb: 'NO',
    nl: 'NL',
    es: 'ES',
    cs: 'CS',
    hu: 'HU',
    pl: 'PL',
    el: 'EL',
    ja: 'JA'
};
export const LANGUAGE_CODES_RENTAL = {
    de: 'DE',
    en: 'EN',
    fr: 'FR',
    it: 'IT',
    sr: 'RS',
    sk: 'SK',
    nb: 'NO',
    nl: 'NL',
    es: 'ES',
    cs: 'CS',
    hu: 'HU',
    pl: 'PL',
    el: 'EL',
    ja: 'JA',
    lv: 'LV',
    et: 'ET',
    lt: 'LT',
    pt: 'PT'
};
export const LANGUAGE_CODES_WITH_LABELS = {
    en_GB: 'English*',
    de_DE: 'German',
    en_US: 'English-US',
    fr_FR: 'French',
    it_IT: 'Italian',
    sr_RS: 'Serbian',
    sk_SK: 'Slovakia',
    nb_NO: 'Norwegian',
    nl_NL: 'Dutch',
    es_ES: 'Spanish',
    es_US: 'Spanish-US',
    cs_CZ: 'Czech',
    hu_HU: 'Hungarian',
    pl_PL: 'Polish',
    el_GR: 'Greek',
    ja_JP: 'Japanese'
    // ru_RU: 'Russian',
    // ru_UA: 'Russian-UA',
    // ru_US: 'Russian-US',
    // zh_CN: 'Chinese',
    // zh_Hans: 'Chinese-Hans',
    // zh_US: 'Chinese-US'
};
export const LANGUAGE_LABELS = {
    de: 'German',
    en: 'English',
    fr: 'French',
    it: 'Italian',
    sr: 'Serbian',
    sk: 'Slovakia',
    nb: 'Norwegian',
    nl: 'Dutch',
    es: 'Spanish',
    cs: 'Czech',
    hu: 'Hungarian',
    pl: 'Polish',
    el: 'Greek',
    ja: 'Japanese'
};

export const APPLICABLE_LANGUAGE_CODES = [
    'DE',
    'EN',
    'FR',
    'IT',
    'SR',
    'SK',
    'NB',
    'NL',
    'ES',
    'CS',
    'HU',
    'PL',
    'EL',
    'JA',
    'EN_US',
    'EN_ES'
];
