import ajax from '@/util/ajax';
import api from '@/config/api';
import axios from 'axios';

const BASE_RP_URL = api.getServiceUrl('catalogueService');
const BASE_DMS_URL = api.getServiceUrl('dmsService');
const API_PATH = '/api/cockpit';

function getRPUrl() {
    return BASE_RP_URL + API_PATH;
}

function getDmsUrl() {
    return BASE_DMS_URL;
}

export default {
    getDmsCertificates() {
        const url = `${getRPUrl()}/rental-partners/dms-certificates`;
        return ajax.get(url);
    },
    createDmsCertificate(data) {
        const url = `${getDmsUrl()}/certificate`;
        return ajax.post(url, data);
    },
    updateDmsCertificate(data) {
        const url = `${getDmsUrl()}/certificate/update-email`;
        return ajax.post(url, data);
    },
    downloadCertificate(businessId, name) {
        if (businessId !== null && businessId !== '' && businessId !== undefined) {
            return axios.get(`${getDmsUrl()}/certificate/download?businessId=${businessId}&commonName=${name}`, {
                responseType: 'blob'
            });
        } else {
            return axios.get(`${getDmsUrl()}/certificate/download?commonName=${name}`, {
                responseType: 'blob'
            });
        }
    },
    deleteCertificate(businessId, name) {
        let url = `${getDmsUrl()}/certificate/delete-certificate?`;
        if (businessId !== null && businessId !== '' && businessId !== undefined) {
            url = url + `rentalPartnerBusinessId=${businessId}&commonName=${name}`;
        } else {
            url = url + `commonName=${name}`;
        }
        return ajax.delete(url);
    }
};
