<template>
  <entity-edit-modal
    ref="entityModal"
    entity-label="User"
    :is-updating="isUpdating"
    :is-dismiss-disabled="isSaving"
    :is-save-button-disabled="!isValid"
    @save-clicked="onSave"
    @dismissed="onDismiss"
  >
    <template v-slot:modalBody>
      <cockpit-user-edit-form
        v-if="entityClone !== null"
        :form-data="entityClone"
        :is-updating="isUpdating"
        v-model:is-valid="isValid"
        :is-valid="isValid"
        @update:isValid="isValid = $event"
      >
      </cockpit-user-edit-form>
    </template>
  </entity-edit-modal>
</template>

<script>
import CockpitUserEditForm from "@/components/cockpitUsers/CockpitUserEditForm";
import CockpitUserFactory from "@/components/cockpitUsers/CockpitUserFactory";
import Notifications from "@/util/notifications";
import EntityEditModal from "@/components/util/EntityEditModal";
import Util from "@/util";

export default {
  name: "CockpitUserEditModal",

  props: {
    user: {
      type: Object,
    },
    isUpdating: {
      type: Boolean,
    },
  },
  components: { EntityEditModal, CockpitUserEditForm },

  data() {
    return {
      isValid: false,
      isSaving: false,
      entityClone: null,
    };
  },

  created() {
    this.$eventBus.$on("openUserEditModal", () => {
      this.$nextTick(() => {
        this.entityClone = Util.object.clone(this.user);

        if (this.entityClone === null) {
          this.entityClone = CockpitUserFactory.create();
        }
      });

      this.$eventBus.$emit("openEntityModal");
    });
  },

  methods: {
    async onSave() {
      const isUpdating = this.isUpdating;
      this.isSaving = true;
      const storeSaveAction = isUpdating ? "updateUser" : "createUser";

      try {
        await this.$store.dispatch(
          `cockpitUsers/${storeSaveAction}`,
          this.entityClone
        );
        this.$eventBus.$emit("hideEntityModal");

        Notifications.showSuccess(
          `The cockpit user was ${
            isUpdating ? "updated" : "created"
          } successfully.`,
          true,
          false
        );
      } catch (e) {
        console.error(e);
        let msg = `Error ${
          isUpdating ? "updating" : "creating"
        } the cockpit user.`;
        if (e.status === 409) {
          msg = "Username already exists.";
        }
        Notifications.showError(msg, true, false);
      } finally {
        this.isSaving = false;
      }
    },

    onDismiss() {
      this.entityClone = null;
    },
  },
};
</script>
