import Ajax from "@/util/ajax";
import Api from "@/config/api";

export default {
  async authenticate(username, password) {
    const LOGIN_BASE_URL =
      Api.getServiceUrl("authenticationService") + "/token/cockpit";
    const formData = new FormData();
    formData.append("username", username);
    formData.append("password", password);
    try {
      const response = await Ajax.post(
        LOGIN_BASE_URL,
        new URLSearchParams({
          username: username,
          password: password,
        }),
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      );
      return response;
    } catch (error) {
      console.error("Error fetching the token:", error);
      throw error;
    }
  },

  async resetPassword(username) {
    const URL =
      Api.getServiceUrl("userAccountService") +
      "/api/cockpit-user-accounts/password-reset-request";
    try {
      const response = await Ajax.post(URL, { username: username });
      const { status } = response;
      return status;
    } catch (error) {
      console.error("An error occured while sending OTP:", error);
      throw error;
    }
  },

  async getUserAccountInfo() {
    const URL =
      Api.getServiceUrl("userAccountService") +
      "/api/cockpit-user-accounts/self";
    try {
      const response = await Ajax.get(URL);
      const data = response.data;
      return data;
    } catch (error) {
      console.error(
        "An error occured while getting cockpit user account info:",
        error
      );
      throw error;
    }
  },

  async getUserAccountAccountInfo() {
    const URL =
      Api.getServiceUrl("userProfileService") + "/api/cockpit-user/self";
    try {
      const response = await Ajax.get(URL);
      const data = response.data;
      return data;
    } catch (error) {
      console.error(
        "An error occured while getting cockpit user account info:",
        error
      );
      throw error;
    }
  },
  
  async changePassword(password, confirmPassword) {
    const URL =
      Api.getServiceUrl("userAccountService") +
      "/api/cockpit-user-accounts/change/self";

    try {
      const payload = {
        password: password,
        repeatedPassword: confirmPassword,
      };
      const response = await Ajax.post(URL, payload);
      return response;
    } catch (error) {
      console.error("An error occured while resetting password:", error);
      throw error;
    }
  },
};
