<template>
    <h2>Welcome to</h2>
    <span :class="{ cockpitTitle: true, large, medium }">
        <br/>
        <i class="fa fa-square blue" aria-hidden="true"></i>
        Rent A Ride <span class="blue">Cockpit</span>
    </span>
</template>

<script>
export default {
    name: 'CockpitTitle',

    props: {
        large: {
            type: Boolean,
            default: false
        },

        medium: {
            type: Boolean,
            default: false
        }
    }
};
</script>

<style lang="scss">

.fa {
    display: inline-block;
    font: normal normal normal 14px/1 FontAwesome;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    .fa-square:before {
        content: "";

        .blue {
        color: #039;
    }
    }
}

.cockpitTitle {
    color: black;
    font-weight: bold;
    white-space: nowrap;
    user-select: none;
    font-size: 24px;
    letter-spacing: -0.02em;

    &.large {
        font-size: 63px;
    }
    &.medium {
        font-size: 44px;
    }

    .blue {
        color: #039;
    }
}
</style>
