<template>
  <div
    class="confirmModal modal fade"
    ref="confirmModal"
    :data-backdrop="backdrop + ''"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header" v-if="$slots.title">
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            @click="onCancel"
          >
            &times;
          </button>

          <h4 class="modal-title">
            <slot name="title"></slot>
          </h4>
        </div>

        <div class="modal-body">
          <slot name="body"></slot>
        </div>

        <div class="modal-footer" v-if="showCancel || showConfirm">
          <button
            type="button"
            class="btn button-sec"
            data-dismiss="modal"
            @click="onCancel"
            v-if="showCancel"
          >
            {{ cancelText }}
          </button>
          <button
            type="button"
            class="btn button-pr"
            data-dismiss="modal"
            @click="onConfirm"
            v-if="showConfirm"
          >
            {{ confirmText }}
          </button>

          <button
            type="button"
            class="btn button-pr"
            data-dismiss="modal"
            @click="onEdit"
            v-if="showEdit"
          >
            {{ editText }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { $ } from "@/assets/vendor";

export default {
  props: {
    confirmText: {
      type: String,
      default: "OK",
    },
    editText: {
      type: String,
      default: "Edit",
    },
    cancelText: {
      type: String,
      default: "Cancel",
    },
    showCancel: {
      type: Boolean,
      default: true,
    },
    showConfirm: {
      type: Boolean,
      default: true,
    },
    showEdit: {
      type: Boolean,
      default: false,
    },
    backdrop: {
      type: Boolean,
      default: true,
    },
  },

  mounted() {
    this.$eventBus.$on("openConfirmModal", (actionConfig) => {
      $(this.$refs.confirmModal).modal("show");
      this.actionConfig = actionConfig;
    });
  },

  methods: {
    onConfirm() {
      if (
        this.actionConfig &&
        Object.prototype.hasOwnProperty.call(this.actionConfig, "onConfirm")
      ) {
        this.actionConfig.onConfirm();
      }
      this.$emit("confirm");
    },
    onEdit() {
      this.$emit("edit");
    },

    onCancel() {
      if (
        this.actionConfig &&
        Object.prototype.hasOwnProperty.call(this.actionConfig, "onCancel")
      ) {
        this.actionConfig.onCancel();
      }
      this.$emit("cancel");
    },
  },
};
</script>

<style lang="scss">
.confirmModal {
  .close {
    outline: none;
  }
}

.modal-content {
  border-radius: 0px !important;
}

.button-pr {
  background-color: #16171a !important;
  border-color: #16171a !important;
  color: #fff !important;
  border-radius: 0 !important;
  font-weight: 700 !important;
  opacity: 1 !important;
}

.button-sec {
  background-color: #fff !important;
  border-color: #16171a !important;
  color: #16171a !important;
  border-radius: 0 !important;
  font-weight: 700 !important;
  opacity: 1 !important;
}

.button-sec:hover,
.button-pr:hover {
  background-color: #0062ff !important;
  border-color: #0062ff !important;
  color: #fff !important;
  text-decoration: none;
}

.btn.button-pr:disabled {
  opacity: 0.5 !important;
  cursor: not-allowed;
}
</style>
