<template>
<div style="width: 100%; background-color: #E6E6E6; padding: 20px;">
    <div class="paginatedTable" :class="{ stickyHeader, highlightRowOnClick }">
        <div class="itemCount" v-if="$slots.itemsCountLabel">
            {{ filteredItems.length }}
            <slot name="itemsCountLabel"></slot>
        </div>

        <div class="overflowContainer" :style="minHeightStyle">
            <table class="table table-striped">
                <thead>
                    <tr>
                        <th id="" v-for="(value, key) in headers" :key="key" :class="[key, { headerNoWrap }]">
                            <div class="header">
                                <i v-if="isSortable(key)" class="fa headerIcon" :class="
                    isSortedBy(key)
                      ? isAscendingSortOrder
                        ? 'fa-sort-asc'
                        : 'fa-sort-desc'
                      : 'fa-sort'
                  " @click="setSortByColumn(key)">
                                </i>
                                <slot :name="'header-' + key"></slot>
                                <table-header-filter :column-key="key" :key="key" :filter="filters[key]" v-if="
                    filters &&
                    filters[key] &&
                    isColumnFilterable(filters[key].type)
                  " :slot="'header-' + key">
                                </table-header-filter>

                                <span>{{ value }}</span>
                            </div>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <template v-if="hasRowsSlot">
                        <slot name="rows" v-for="item in pageItems" :item="item"> </slot>
                    </template>
                    <template v-else>
                        <tr v-for="(item, index) in pageItems" :key="index">
                            <td v-for="(value, key) in headers" :key="key" :class="key">
                                <span v-html="cellRenderer(item, key)"></span>
                                <slot name="cellSlot" :item="item" :columnKey="key"></slot>
                            </td>
                        </tr>
                    </template>
                </tbody>
            </table>
        </div>

        <nav class="paginationNav" v-if="filteredItems.length > pageSize">
            <ul class="pagination">
                <li :class="{ disabled: isFirstPage }">
                    <a @click="setActivePage(0)">
                        <i class="fa fa-angle-double-left"></i>
                    </a>
                </li>
                <li :class="{ disabled: isFirstPage }">
                    <a @click="switchToPrevPage()">
                        <i class="fa fa-angle-left"></i>
                    </a>
                </li>
                <li v-if="limitedPages[0] !== 0">
                    <a class="nonClickable">...</a>
                </li>
                <li v-for="index in limitedPages" :key="index" :class="{ active: isActivePage(index) }">
                    <a @click="setActivePage(index)">{{ index + 1 }}</a>
                </li>
                <li v-if="limitedPages[limitedPages.length - 1] !== numberOfPages - 1">
                    <a class="nonClickable">...</a>
                </li>
                <li :class="{ disabled: isLastPage }">
                    <a @click="switchToNextPage()">
                        <i class="fa fa-angle-right"></i>
                    </a>
                </li>
                <li :class="{ disabled: isLastPage }">
                    <a @click="setActivePage(numberOfPages - 1)">
                        <i class="fa fa-angle-double-right"></i>
                    </a>
                </li>
            </ul>
        </nav>
    </div>
</div>
</template>

<script>
import TableHeaderFilter from "@/components/util/paginatedTable/filters/TableHeaderFilter";
import tableColumnService from "@/components/util/paginatedTable/tableColumnService";
const PAGINATOR_NUM_PAGES = 11; // should be odd

const SORT_ORDER = {
    ASCENDING: "ASCENDING",
    DESCENDING: "DESCENDING",
};

export default {
    name: "PaginatedTable",
    mounted() {
        this.$eventBus.$on("selection-changed-filter", () => {
            this.setActivePage(0);
        });
    },
    components: {
        TableHeaderFilter
    },
    props: {
        headers: {
            type: Object,
            required: true,
        },
        items: {
            type: Array,
            required: true,
        },
        cellRenderer: {
            type: Function,
            default: (row, key) => (row ? row[key] : ""),
        },
        sortFormatter: {
            type: Function,
            default: (row, key) => (row ? row[key] : ""),
        },
        pageSize: {
            type: [Number, String],
            default: 10,
        },
        stickyHeader: {
            type: Boolean,
            default: true,
        },
        permissions: {
            type: Boolean,
            default: false,
        },
        headerNoWrap: {
            type: Boolean,
            default: false,
        },
        minHeight: {
            type: String,
            default: null,
        },
        sortingDisabledFor: {
            type: Array,
            default: () => [],
        },
        filters: {
            type: Object,
            required: false,
            default: () => null,
        },
        highlightRowOnClick: {
            type: Boolean,
            required: false,
            default: () => false,
        },
    },

    data() {
        return {
            activePage: 0,
            sortByColumn: Object.keys(this.headers)[0],
            sortOrder: SORT_ORDER.ASCENDING,
            selectedRow: null,
        };
    },

    computed: {
        filteredItems() {
            if (this.filters === null) {
                return this.items;
            }
            return this.items.filter((row) => {
                for (const [key, filterEntry] of Object.entries(this.filters)) {
                    if (
                        Object.prototype.hasOwnProperty.call(row, key) &&
                        !filterEntry.doesMatch(row[key])
                    ) {
                        return false;
                    } else if (
                        !Object.prototype.hasOwnProperty.call(row, key) &&
                        filterEntry.isActive()
                    ) {
                        return false;
                    }
                }
                return true;
            });
        },

        hasRowsSlot() {
            return !!this.$slots.rows;
        },

        sortedItems() {
            if (!this.filteredItems) {
                return [];
            }

            const columnName = this.sortByColumn;
            const sortOrder = this.sortOrder;

            return this.filteredItems.slice().sort((first, second) => {
                let firstValue = this.sortFormatter(first, columnName);
                let secondValue = this.sortFormatter(second, columnName);

                if (columnName === "outletId") {
                    if (firstValue != undefined) {
                        firstValue = Number(firstValue);
                    }
                    if (secondValue != undefined) {
                        secondValue = Number(secondValue);
                    }
                }
                // check for undefined to move missing values to the end when sorting ascendingly
                if (firstValue === undefined || firstValue > secondValue) {
                    return sortOrder === SORT_ORDER.ASCENDING ? 1 : -1;
                } else if (secondValue === undefined || secondValue > firstValue) {
                    return sortOrder === SORT_ORDER.ASCENDING ? -1 : 1;
                }
                return 0;
            });
        },

        pageItems() {
            const firstItemIndex = this.activePage * this.pageSize;
            const lastItemIndex =
                this.activePage * this.pageSize + (this.pageSize - 1);
            return this.sortedItems.slice(firstItemIndex, lastItemIndex + 1);
        },

        isAscendingSortOrder() {
            return this.sortOrder === SORT_ORDER.ASCENDING;
        },

        numberOfPages() {
            const numberOfPages = Math.ceil(this.sortedItems.length / this.pageSize);
            return isNaN(numberOfPages) ? 0 : numberOfPages;
        },

        pagesCount() {
            const count = this.numberOfPages;
            return Array.from(new Array(count).keys());
        },

        limitedPages() {
            const numPages = this.numberOfPages;
            if (numPages < PAGINATOR_NUM_PAGES) {
                return this.pagesCount;
            }

            const numPagesLeftRight = Math.floor(PAGINATOR_NUM_PAGES / 2);
            const startIndex = Math.max(
                0,
                Math.min(
                    numPages - PAGINATOR_NUM_PAGES,
                    this.activePage - numPagesLeftRight
                )
            );
            return this.pagesCount.slice(
                startIndex,
                startIndex + PAGINATOR_NUM_PAGES
            );
        },

        isFirstPage() {
            return this.activePage === 0;
        },

        isLastPage() {
            return this.activePage === this.numberOfPages - 1;
        },

        minHeightStyle() {
            if (this.minHeight === null) {
                return null;
            }
            return {
                "min-height": this.minHeight
            };
        },

        activeFilters() {
            return Object.entries(this.filters)
                .filter(([, filterEntry]) => filterEntry.isActive())
                .map((entry) => entry[0]);
        },
    },

    methods: {
        setActivePage(index) {
            this.activePage = Math.max(0, Math.min(this.numberOfPages - 1, index));
        },

        isActivePage(index) {
            return this.activePage === index;
        },

        switchToPrevPage() {
            if (!this.isFirstPage) {
                this.setActivePage(this.activePage - 1);
            }
        },

        switchToNextPage() {
            if (!this.isLastPage) {
                this.setActivePage(this.activePage + 1);
            }
        },

        setSortByColumn(columnName) {
            if (this.sortByColumn === columnName) {
                this.sortOrder =
                    this.sortOrder === SORT_ORDER.DESCENDING ?
                    SORT_ORDER.ASCENDING :
                    SORT_ORDER.DESCENDING;
            } else {
                this.sortByColumn = columnName;
                this.sortOrder = SORT_ORDER.ASCENDING;
            }
        },

        isSortedBy(columnName) {
            return this.sortByColumn === columnName;
        },

        isSortable(columnName) {
            return !this.sortingDisabledFor.includes(columnName);
        },

        isColumnFilterable(type) {
            return type && tableColumnService.isColumnFilterable(type);
        },
    },

    watch: {
        items() {
            this.setActivePage(0);
        },
    },
};
</script>

<style lang="scss">
@import "~@/assets/styles/_settings";

.paginatedTable {
    @import "~@/../node_modules/bootstrap-sass/assets/stylesheets/bootstrap/variables";
    @import "~@/../node_modules/bootstrap-sass/assets/stylesheets/bootstrap/mixins";
    @import "~@/../node_modules/bootstrap-sass/assets/stylesheets/bootstrap/pagination";
    @import "~@/../node_modules/bootstrap-sass/assets/stylesheets/bootstrap/tables";

    .table {
        thead {
            >tr {
                >th {
                    background: white !important;
                }
            }
        }

        tbody tr:nth-child(even) {
            background-color: #EBEBEB !important;
        }

        tbody tr:nth-child(odd) {
            background-color: #F2F2F2 !important;
        }
    }

    .itemCount {
        font-weight: bold;
        text-align: center;
        margin-bottom: 15px;
    }

    .overflowContainer {
        width: 95%;
        margin: 10px auto;
        overflow-x: auto;
    }

    table {
        border-collapse: collapse;
        border-spacing: 0;
        margin-bottom: 0 !important;

        th {
            vertical-align: middle;
            padding: 0 15px 0 0;

            &.headerNoWrap {
                white-space: nowrap;
                padding-bottom: 20px !important;
                height: 60px;
            }

            .header {
                display: flex;
                flex-direction: row;

                .headerIcon {
                    margin-top: 3px;
                    margin-right: 5px;
                }
            }
        }

        td {
            min-width: 125px;
            padding: 0;
        }
    }

    &.stickyHeader {
        .overflowContainer {
            overflow-y: auto;
        }

        table {
            th {
                top: 0;
                position: sticky;
                background-color: $color_white;
                z-index: $z-index-raised-1;
                box-shadow: 0 1px $color_gray;
            }
        }
    }

    .paginationNav {
        text-align: center;
    }

    .pagination {
        li {
            color: black;
            cursor: pointer;

            a {
                background-color: $color_white;
                color: inherit;
                border-color: $color_ci_blue_shade4;
            }

            a:hover {
                color: black !important;
                background-color: white !important;
                border-color: white !important;
            }
        }

        li.active {

            a,
            a:hover {
                background-color: black !important;
                border-color: black !important;
                color: $color_white !important;
            }
        }

        .nonClickable {
            pointer-events: none;
        }
    }
}
</style>
