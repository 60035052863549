import { COLUMN_TYPE } from '@/components/util/paginatedTable/columnTypes';

export const COLUMNS = {
    dealerId: {
        type: COLUMN_TYPE.arbitraryEnum || COLUMN_TYPE.arbitraryEqual,
        title: 'BUNO',
        initial: true
    },
    name: {
        type: COLUMN_TYPE.arbitrary,
        title: 'Name',
        initial: true
    },
    type: {
        type: COLUMN_TYPE.enum,
        title: 'Type',
        initial: true
    },
    hub: {
        type: COLUMN_TYPE.enum,
        title: 'Hub',
        initial: true
    },
    region: {
        type: COLUMN_TYPE.enum,
        title: 'Region',
        initial: true
    },
    distanceUnit: {
        type: COLUMN_TYPE.enum,
        title: 'Unit of Distance',
        initial: true
    },
    country: {
        type: COLUMN_TYPE.enum,
        title: 'Country',
        initial: true
    },
    distributionPartnerId: {
        type: COLUMN_TYPE.arbitrary,
        title: 'Distribution Partner Id',
        initial: true
    },
    outletId: {
        type: COLUMN_TYPE.arbitrary,
        title: 'Outlet Id',
        initial: true
    },
    languageCode: {
        type: COLUMN_TYPE.enum,
        title: 'Language'
    },
    city: {
        type: COLUMN_TYPE.arbitrary,
        title: 'City',
        initial: true
    },
    timezone: {
        type: COLUMN_TYPE.enum,
        title: 'Timezone'
    },
    currency: {
        type: COLUMN_TYPE.enum,
        title: 'Currency'
    },
    rentalSeasonFrom: {
        type: COLUMN_TYPE.dateRange,
        title: 'Season Start',
        initial: false
    },
    rentalSeasonTill: {
        type: COLUMN_TYPE.dateRange,
        title: 'Season End',
        initial: false
    },
    enabled: {
        type: COLUMN_TYPE.enum,
        title: 'Enabled',
        initial: true
    },
    active: {
        type: COLUMN_TYPE.enum,
        title: 'Active',
        initial: true
    },
    bikeVisibility: {
        type: COLUMN_TYPE.enum,
        title: 'Bike Visibility',
        initial: true
    },
    bookingEmail: {
        type: COLUMN_TYPE.arbitrary,
        title: 'Booking E-Mail',
        initial: true
    },
    addressCode: {
        type: COLUMN_TYPE.arbitrary,
        title: 'Google Plus Code',
        initial: false
    },

    zip: {
        type: COLUMN_TYPE.arbitrary,
        title: 'Zip'
    },
    addressLine1: {
        type: COLUMN_TYPE.arbitrary,
        title: 'Address Line 1'
    },
    addressLine2: {
        type: COLUMN_TYPE.arbitrary,
        title: 'Address Line 2'
    },

    infoEmail: {
        type: COLUMN_TYPE.arbitrary,
        title: 'Info Mail'
    },
    phone: {
        type: COLUMN_TYPE.arbitrary,
        title: 'Phone'
    },
    bmwPayStatus: {
        type: COLUMN_TYPE.enum,
        title: 'BMW Pay Status',
        initial: true
    },
    paymentProviderLastUpdated: {
        type: COLUMN_TYPE.arbitrary,
        title: 'Payment Provider Last Updated'
    },
    companyName: {
        type: COLUMN_TYPE.arbitrary,
        title: 'Registered Company Name',
        initial: true
    },
    registrationKind: {
        type: COLUMN_TYPE.arbitrary,
        title: 'Registration Kind',
        initial: true
    },
    action: {
        title: 'Action',
        initial: true
    }
};
