import Util from '@/util';
import { reactive } from 'vue';

const HIDE_TIMEOUT_DURATION = 4000;

export default {
  namespaced: true,

  state: reactive({
    messages: []
  }),

  getters: {
    messages: state => state.messages
  },

  actions: {
    showMessage({ commit, state }, { type, message, hideAfterTimeout = false, uniqueMessageText = false, info = undefined }) {
      if (uniqueMessageText) {
        // Make sure the given message text isn't displayed yet
        const existingMessage = state.messages.find(msg => msg.message === message && msg.type === type);
        if (existingMessage) {
          return existingMessage.id;
        }
      }

      const config = {
        id: Util.string.uuid(),
        type,
        message
      };

      if (info) {
        config.info = info;
      }

      if (hideAfterTimeout) {
        const timeout = typeof hideAfterTimeout === 'number' ? hideAfterTimeout : HIDE_TIMEOUT_DURATION;
        config.timeout = setTimeout(() => {
          commit('DELETE_MESSAGE', config.id);
        }, timeout);
      }

      commit('ADD_MESSAGE', config);
      return config.id;
    },

    hideMessage({ commit }, id) {
      commit('DELETE_MESSAGE', id);
    },

    hideAllMessages({ commit }) {
      commit('DELETE_ALL_MESSAGES');
    }
  },

  mutations: {
    ADD_MESSAGE(state, message) {
      state.messages.push(message);
    },

    DELETE_MESSAGE(state, id) {
      const index = state.messages.findIndex(msg => msg.id === id);
      if (index > -1) {
        clearTimeout(state.messages[index].timeout);
        state.messages.splice(index, 1);
      }
    },

    DELETE_ALL_MESSAGES(state) {
      state.messages = [];
    }
  }
};
