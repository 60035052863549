<template>
<div class="checkboxGrid">
    <div v-if="selectionButtons && !radio" class="selectionButtons">
        <icon-button icon="fa-check" class="mr-10" :small="smallButtons" secondary @click="toggleAll(true)">Select all</icon-button>
        <icon-button icon="fa-ban" :small="smallButtons" secondary @click="toggleAll(false)">Unselect all</icon-button>
    </div>

    <div v-if="$slots.default" class="slot">
        <slot></slot>
    </div>

    <div class="itemGrid" :class="{ limitedHeightBox: height }" :style="heightStyle">
        <div v-for="(column, columnIndex) in layoutedItems" :key="columnIndex" class="itemColumn">
            <div v-for="(item, rowIndex) in column" :key="rowIndex" :class="{ itemBox: true, selected: item[fields.selected] }" @click="toggleItem(item)">
                <div>
                    <i :class="['fa', 'checkboxIcon', getIcon(item)]"></i>
                </div>
                <div class="itemTitleContainer" :style="itemContainerStyle">
                    <div class="itemTitle">
                        <slot :item="item" name="titlePrefix"></slot>
                        <span class="title">{{ item[fields.title] }}</span>
                        <slot :item="item" name="titlePostfix"></slot>
                    </div>
                    <div class="itemSubTitle" v-if="subtitle && item[fields.title] !== item[fields.subtitle]">
                        {{ item[fields.subtitle] }}
                    </div>
                </div>
            </div>

            <div class="artificalPadding" v-if="height"></div>
        </div>
    </div>
</div>
</template>

<script>
import Util from '@/util';
import LayoutService from '@/util/services/layoutService';

import IconButton from '@/components/util/IconButton';

export default {
    name: 'CheckboxGrid',

    components: {
        IconButton
    },

    props: {
        items: {
            type: Array,
            required: true
        },
        fields: {
            type: Object,
            validator(value) {
                return (
                    Object.prototype.hasOwnProperty.call(value, 'title') &&
                    Object.prototype.hasOwnProperty.call(value, 'subtitle') &&
                    Object.prototype.hasOwnProperty.call(value, 'selected')
                );
            },
            default () {
                return {
                    title: 'title',
                    subtitle: 'id',
                    selected: 'selected'
                };
            }
        },
        subtitle: {
            type: Boolean,
            default: false
        },
        selectionButtons: {
            type: Boolean,
            default: false
        },
        smallButtons: {
            type: Boolean,
            default: false
        },
        columns: {
            type: [Number, String],
            default: 1
        },
        radio: {
            type: Boolean,
            default: false
        },
        height: {
            type: String,
            default: ''
        },
        itemHeight: {
            type: [Number, String],
            default: ''
        }
    },

    computed: {
        layoutedItems() {
            return LayoutService.columnLayoutByColumns(this.items, +this.columns);
        },

        heightStyle() {
            if (!this.height) {
                return null;
            }
            return {
                height: this.height
            };
        },

        itemContainerStyle() {
            if (this.itemHeight === '') {
                return null;
            }
            return {
                height: this.itemHeight
            };
        }
    },

    methods: {
        getIcon(item) {
            const isSelected = item[this.fields.selected];
            if (this.radio) {
                return isSelected ? 'fa-dot-circle-o' : 'fa-circle-o';
            }
            return isSelected ? 'fa-check-square' : 'fa-square-o';
        },

        toggleItem(item) {
            if (this.radio) {
                this.items.forEach(e => (e[this.fields.selected] = false));
            }
            item[this.fields.selected] = !item[this.fields.selected];
            this.$emit('toggle', item);
            this.$eventBus.$emit('selection-changed-filter', item);
        },

        toggleAll(isSelected) {
            if (this.radio) {
                return;
            }
            this.items.forEach(item => (item[this.fields.selected] = isSelected));
            this.$emit('toggle-all', Util.object.clone(this.items));
        }
    }
};
</script>

<style scoped lang="scss">
@import '~@/assets/styles/_settings';

.checkboxGrid {
    .selectionButtons {
        text-align: center;
        margin: 5px 0 15px;
    }

    .itemGrid {
        width: 100%;
        display: flex;
        flex-direction: row;

        &.limitedHeightBox {
            overflow-y: scroll;
            border: 1px solid $color_bootstrap_table_border;
            padding: 5px;
        }
    }

    .itemColumn {
        flex-basis: 0;
        flex-grow: 1;

        &:not(:first-child) {
            margin-left: 10px;
        }
    }

    .itemBox {
        display: flex;
        flex-direction: row;
        align-items: center;
        width: 120%;
        padding: 4px 15px;
        margin-top: 5px;
        overflow: hidden;
        cursor: pointer;

        &:first-child {
            margin-top: 0;
        }

        // &:hover {
        //     background: $color_gray_whisper;
        // }

        // &.selected {
        //     background: $color_ci_blue_shade4;

        //     &:hover {
        //         background: $color_ci_blue_shade3;
        //     }
        // }
    }

    .itemTitleContainer {
        display: flex;
        flex-direction: column;
        justify-content: center;
        flex: 1;
    }

    .itemTitle {
        display: flex;
        flex-direction: row;
        align-items: center;

        .title {
            flex: 1;
            font-size: 16px;
            color: #666;
            font-weight: 100;
        }
    }

    .itemSubTitle {
        font-size: 11px;
        white-space: nowrap;
        max-width: 0;
    }

    .checkboxIcon {
        width: 30px;
        color: $color_ci_smalt;
    }

    .slot {
        margin-bottom: 15px;
    }

    .artificalPadding {
        height: 5px;
    }

    .mr-10 {
        margin-right: 10px !important;
    }

    .fa {
        font-size: 30px;
        color: #0062ff;
        border-radius: 0px !important;
    }
}
</style>
