function isFieldValid(el, validationRules) {
  if (validationRules.required && el.value === "") {
    return false;
  }
  if (validationRules.regex && !validationRules.regex.test(el.value)) {
    return false;
  }
  if (validationRules.custom && !validationRules.custom()) {
    return false;
  }
  if (
    validationRules.min &&
    (isNaN(+el.value) || el.value < validationRules.min)
  ) {
    return false;
  }
  if (
    validationRules.max &&
    (isNaN(+el.value) || el.value > validationRules.max)
  ) {
    return false;
  }
  return true;
}

function performValidation(el, validationRules) {
  if (!validationRules) return;

  const valid = isFieldValid(el, validationRules);
  validationRules.isValid = valid;
  el.classList[valid ? "remove" : "add"]("error");
}

export default {
  mounted(el, binding) {
    performValidation(el, binding.value);

    const updateValue = () => {
      performValidation(el, binding.value);
    };

    el.addEventListener("input", updateValue);
    el.addEventListener("blur", updateValue);

    setTimeout(() => {
      updateValue();
    }, 500);
  },
  updated(el, binding) {
    performValidation(el, binding.value);
  }
};
