<template>
  <div class="tripsHistoryTableColumnSelection">
    <icon-button primary icon="fa-eye" @click="openModal">
      Column visibility
    </icon-button>

    <confirm-modal ref="modal" :show-cancel="false" :show-confirm="false">
      <template v-slot:title>
        <span> <i class="fa fa-columns"></i> Column Visibility </span>
      </template>
      <template v-slot:body>
        <div>
          <checkbox-grid
            :items="columnItems"
            columns="3"
            selection-buttons
            small-buttons
            @toggle="onSelectionChanged"
            @toggle-all="onSelectionChanged"
          >
          </checkbox-grid>
        </div>
      </template>
    </confirm-modal>
  </div>
</template>

<script>
import CheckboxGrid from "@/components/util/CheckboxGrid";
import ConfirmModal from "@/components/util/ConfirmModal";
import IconButton from "@/components/util/IconButton";

export default {
  name: "TableColumnSelection",

  props: {
    tableHeaders: {
      type: Array,
      required: true,
    },
  },
  components: {
    CheckboxGrid,
    ConfirmModal,
    IconButton,
  },

  data() {
    return {
      columnItems: [],
    };
  },

  methods: {
    openModal() {
      this.columnItems = [...this.tableHeaders];
      // this.$refs.modal.$emit('open');
      this.$eventBus.$emit("openConfirmModal");
    },

    onSelectionChanged() {
      this.$emit("selection-changed", this.columnItems);
    },
  },
};
</script>

<style>
.button-pr {
    background-color: #16171a !important;
    border-color: #16171a !important;
    color: #fff !important;
    border-radius: 0 !important;
    font-weight: 700 !important;
}
</style>
