// Split the given array entries into row-based columns, e.g. [c11, c12, c21, c22] => [[c11, c12], [c21, c22]] where each subarray is a column
function columnLayoutByColumns(entries, numberOfColumns) {
    if (numberOfColumns === 1) {
        return [entries];
    }

    const result = [];
    let columnLength = Math.ceil(entries.length / numberOfColumns);
    let prevColumnLength;
    for (let i = 0; i < entries.length; i += prevColumnLength) {
        result.push(entries.slice(i, i + columnLength));

        prevColumnLength = columnLength;
        const newColumnLength = Math.ceil((entries.length - columnLength) / (numberOfColumns - 1));
        if (newColumnLength <= columnLength) {
            columnLength = newColumnLength;
        }
    }

    return result;
}

// Split the given array entries into column-based rows, e.g. [c11, c12, c21, c22] => [[c11, c21], [c12, c22]] where each subarray is a row
function columnLayoutByRows(entries, numberOfColumns) {
    const columns = columnLayoutByColumns(entries, numberOfColumns);

    // Rotate arrays by 90°
    const result = [];
    const rowsNum = Math.ceil(entries.length / numberOfColumns);
    for (let i = 0; i < rowsNum; ++i) {
        const row = [];

        columns.forEach(column => {
            const item = column.shift();
            if (item) {
                row.push(item);
            }
        });

        result.push(row);
    }

    return result;
}

export default {
    columnLayoutByColumns,
    columnLayoutByRows
};
