// store/modules/login.js
import { LoginApi } from "@/server/index";
import Ajax from "@/util/ajax";
import router from "@/router"; // Import the router instance

const initialState = {
  isAuthenticated: false,
  idToken: "",
  cockpitUserInfo: null,
  cockpitUserAccountInfo: null,
};

export default {
  namespaced: true,
  state: initialState,
  mutations: {
    setAuthentication(state, status) {
      state.isAuthenticated = status;
    },
    setIdToken(state, data) {
      state.idToken = data;
      localStorage.setItem("idToken", data);
    },
    setCockpitUserInfo(state, data) {
      state.cockpitUserInfo = data;
    },
    setCockpitUserAccountInfo(state, data) {
      state.cockpitUserAccountInfo = data;
    },
  },
  actions: {
    async login({ commit }, credentials) {
      try {
        const { data } = await LoginApi.authenticate(
          credentials.username,
          credentials.password
        );
        commit("setIdToken", data);
        Ajax.init();
        const [response1, response2] = await Promise.all([
          LoginApi.getUserAccountInfo(),
          LoginApi.getUserAccountAccountInfo(),
        ]);
        commit("setCockpitUserInfo", response1);
        localStorage.setItem("cockpitUserInfo", response2.userGroupType);
        commit("setCockpitUserAccountInfo", response2);
        commit("setAuthentication", true);
      } catch (error) {
        console.error("Login failed", error);
        commit("setAuthentication", false);
        throw error;
      }
    },
    async resetPassword({ commit }, username) {
      try {
        await LoginApi.resetPassword(username);
      } catch (error) {
        console.error("OTP request failed failed", error);
        throw error;
      }
    },
    async changePassword({ commit }, requestData) {
      try {
        const response = await LoginApi.changePassword(
          requestData.password,
          requestData.confirmPassword
        );
        commit("setCockpitUserInfo", response.data);
      } catch (error) {
        console.error("Password reset request failed", error);
        throw error;
      }
    },
    async logout({ commit }) {
      commit("setAuthentication", false);
      commit("setCockpitUserInfo", null);
      commit("setCockpitUserAccountInfo", null);
      commit("setIdToken", "");
      localStorage.removeItem("idToken"); // Adjust this to your actual token name
    },
    initializeAuthentication({ commit }) {
      commit("setCockpitUserInfo", localStorage.getItem("cockpitUserInfo"));
      commit("setAuthentication", true);

      router.push("/rentalPartners");
    },
  },
  getters: {
    cockpitUserDetails: (state) => state.cockpitUserInfo,
    getIdToken: (state) => state.idToken,
    cockpitUserAccountInfo: (state) => state.cockpitUserAccountInfo,
    isAuthenticated: (state) => state.isAuthenticated,
  },
};
