<template>
  <nav class="navbar">
    <div class="navbar-left">
      <router-link to="/rentalPartners" class="nav-link"
        >Rental Partners</router-link
      >
      <router-link v-if="isAllow()" to="/cockpitUsers" class="nav-link"
        >Cockpit Users</router-link
      >
      <router-link to="/promotions" class="nav-link">Promotion</router-link>
      <router-link to="/users" class="nav-link">Users</router-link>
      <router-link
        to="/dmsCertificates"
        @click="InlineButtonClickHandlerForCertificates"
        class="nav-link"
        >DMS Certificates</router-link
      >
      <router-link
        v-if="isAuthenticated"
        to="/login"
        class="nav-link"
        @click="handleLogout"
        >Logout</router-link
      >
    </div>
    <div class="navbar-right">
      <router-link to="/" class="nav-link"
        ><img
          style="width: 170px"
          src="../../../src/assets/bmw-motorrad_id-mod.svg"
          alt="Logo"
      /></router-link>
    </div>
  </nav>
</template>

<script>
import { mapGetters, mapActions, mapState } from "vuex";

export default {
  data() {
    return {
      userGroup: "",
    };
  },
  mounted() {
    this.userGroup = localStorage.getItem("cockpitUserInfo");
  },
  methods: {
    ...mapActions("login", ["logout"]),
    ...mapGetters("login", ["cockpitUserAccountInfo"]),

    async handleLogout() {
      await this.logout();
      this.$router.push("/login"); // Redirect to the login page
    },
    InlineButtonClickHandlerForCertificates() {
      const dmsRentalPartnersLength =
        this.$store.getters["dmsCertificates/dmsCertificates"].length;
      if (dmsRentalPartnersLength == 0) {
        this.$store.dispatch("dmsCertificates/getDmsCertificates");
      }
    },
    showCockpitUserTab() {
      if (this.cockpitUserAccountInfo) {
        const { userGroupType } = this.cockpitUserAccountInfo;
        return userGroupType === "COCKPIT_ADMIN";
      }
      return false;
    },
    isAllow() {
      return this.userGroup === "COCKPIT_ADMIN";
    },
  },
  computed: {
    ...mapState("login", ["isAuthenticated"]),
  },
};
</script>

<style scoped>
.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  background-color: white;
  color: black;
}

.navbar-left {
  display: flex;
}

a {
  font-size: 22px;
  font-weight: 500;
  margin-right: 20px;
  color: black !important;
  text-decoration: none;
}

a:hover {
  font-weight: 700 !important;
}

.navbar-right img {
  max-width: 100px;
  height: auto;
}

.active {
  font-weight: 600 !important;
}
</style>
