<template>
    <div class="splashScreen">
        <div class="jumbotron">
            <div class="content">
                <slot></slot>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'SplashScreen'
};
</script>

<style lang="scss">
.splashScreen {
    .jumbotron {
        background: #f5f5f5 url('@/assets/images/drivenow_bg.png') no-repeat bottom right;
        height: 100vh;
        user-select: none;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: transparent !important;
    }

    .content {
        text-align: center;
    }
}
</style>
