import Ajax from '@/util/ajax';
import Api from '@/config/api';

const BASE_URL = Api.getServiceUrl('promotionService');
const API_PATH = '/api/cockpit';

function getUrl() {
    return BASE_URL + API_PATH;
}

function getRPUrl() {
    return Api.getServiceUrl('catalogueService') + API_PATH;
}
export default {
    getPromotions() {
        const url = `${getUrl()}/promotions/list-info`;
        return Ajax.get(url);
    },
    getPromotionValues() {
        const url = `${getUrl()}/promotions/values`;
        return Ajax.get(url);
    },
    createUser(data) {
        const url = `${getUrl()}/promotions`;
        return Ajax.post(url, data);
    },
    createDraft(data) {
        const url = `${getUrl()}/promotions/draft-promotion`;
        return Ajax.post(url, data);
    },
    updateDraft(promoId, data) {
        const url = `${getUrl()}/promotions/draft-promotion/${promoId}`;
        return Ajax.post(url, data);
    },
    updatePromotion(promoId, data) {
        const url = `${getUrl()}/promotions/${promoId}`;
        return Ajax.post(url, data);
    },
    deletePromotion(promoId) {
        const url = `${getUrl()}/promotions/${promoId}`;
        return Ajax.delete(url);
    },
    copyPromotion(promoId) {
        const url = `${getUrl()}/promotions/copy/${promoId}`;
        return Ajax.post(url);
    },
    activatePromotion(promoId) {
        const url = `${getUrl()}/promotions/activate/${promoId}`;
        return Ajax.post(url, []);
    },
    getAvailableRentalPartners(promoId) {
        if (promoId === undefined || promoId === null) {
            promoId = '';
        }
        const url = `${getRPUrl()}/rental-partners/available?id=${promoId}`;
        return Ajax.get(url);
    },
    getPromotionDetails(promoId) {
        const url = `${getUrl()}/promotions/detailed/${promoId}`;
        return Ajax.get(url);
    }
};
