import validate from '@/components/util/directives/validate';
export default {
    directives: {
        validate
    },
    props: {
        isValid: {
            type: Boolean,
            required: true
        }
    },
    watch: {
        isEverythingValid: {
            immediate: true,
            handler(value) {
                this.$emit('update:isValid', value);
            }
        }
    },
    computed: {
        isEverythingValid() {
            return Object.values(this.validations).every(e => e.isValid);
        }
    }
};
