import { COLUMN_TYPE } from '@/components/util/paginatedTable/columnTypes';
import { FilterEntry } from '@/components/util/paginatedTable/FilterEntry';
import Util from '@/util';

const columnTypeCheckers = {
    isColumnFilterable(type) {
        return type && type !== COLUMN_TYPE.location;
    }
};

function toEnumModelEntries(items, key) {
    if (key === 'enabled') {
        return items.map(entry => ({
            item: entry,
            selected: entry == 'true' ? true : false
        }));
    } else {
        return items.map(entry => {
            if (entry === '') {
                return {
                    item: 'BLANK',
                    selected: true
                };
            } else {
                return {
                    item: entry,
                    selected: true
                };
            }
        });
    }
}

function getEnumFilterModelEntries(key, rawData) {
    let entries = Util.array.unique(rawData.map(row => row[key] + ''));

    Util.array.sortLocale(entries);

    return toEnumModelEntries(entries, key);
}

function getDateRangeFilterModelEntries() {
    return {
        startDate: '',
        endDate: ''
    };
}

function getFilterModelWithVehicleData(filterKey, filterType, vehicleData) {
    switch (filterType) {
        case COLUMN_TYPE.enum:
            return getEnumFilterModelEntries(filterKey, vehicleData);
        case COLUMN_TYPE.dateRange:
            return getDateRangeFilterModelEntries();
        default:
            return '';
    }
}

export default {
    ...columnTypeCheckers,

    getInitialTableHeaders(columns) {
        return Object.entries(columns).map(([key, config]) => {
            return {
                ...config,
                key,
                selected: config.initial
            };
        });
    },

    buildFiltersForTableData(columns, vehicleData) {
        const filters = {};

        for (const key of Object.keys(columns)) {
            const filterType = columns[key].type;
            if (!columnTypeCheckers.isColumnFilterable(filterType)) {
                continue;
            }
            const filterEntry = new FilterEntry();
            filterEntry.model = getFilterModelWithVehicleData(key, filterType, vehicleData);
            filterEntry.type = filterType;
            filterEntry.columnName = key;
            filters[key] = filterEntry;
        }

        return filters;
    }
};
