import { createRouter, createWebHistory } from 'vue-router';
import CockpitUsersSetup from '@/components/cockpitUsers/CockpitUsersSetup.vue';
import LoginForm from '@/components/core/login/loginForm.vue';
import Home from '@/components/core/splash/Home.vue'; // Ensure the correct path
import store from '@/store'; // Import the store

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  linkActiveClass: "active",
  routes: [
    {
      path: '/',
      name: 'home',
      component: Home,
      meta: { requiresAuth: true }, // Add requiresAuth meta field
    },
    {
      path: "/rentalPartners",
      name: "rentalPartners",
      meta: { requiresAuth: true }, // Add requiresAuth meta field
      component: async () => {
        return import(
          /* webpackChunkName: "RentalPartners" */ "@/components/rentalPartners/RentalPartnersSetup.vue"
        );
      },
      props: true
    },
    {
      path: "/users/rentalPartner/:rentalPartnerBusinessId",
      name: "usersByRentalPartnerDealerId",
      component: async () => {
        return import(
          /* webpackChunkName: "Users" */ "@/components/users/UsersSetup.vue"
        );
      },
    },
    {
      path: "/users/add/rentalPartner/:rentalPartnerBusinessId",
      name: "addUsersWithRentalPartnerDealerId",
      props: { showAddUserPopup: true },
      component: async () => {
        return import(
          /* webpackChunkName: "Users" */ "@/components/users/UsersSetup.vue"
        );
      },
    },
    {
      path: "/users",
      name: "users",
      meta: { requiresAuth: true }, // Add requiresAuth meta field
      component: async () => {
        return import(
          /* webpackChunkName: "Users" */ "@/components/users/UsersSetup.vue"
        );
      },
    },
    {
      path: "/promotions",
      name: "promotions",
      meta: { requiresAuth: true }, // Add requiresAuth meta field
      component: async () => {
        return import(
          /* webpackChunkName: "Promotions" */ "@/components/promotions/PromotionsSetup.vue"
        );
      },
    },
    {
      path: "/dmsCertificates",
      name: "dmsCertificates",
      component: async () => {
        return import(
          /* webpackChunkName: "DmsCertificates" */ "@/components/dmsCertificates/DmsCertificatesSetup.vue"
        );
      },
    },
    {
      path: "/cockpitUsers",
      name: "cockpitUsers",
      component: CockpitUsersSetup,
      meta: { requiresAuth: true }, // Add requiresAuth meta field
    },
    {
      path: '/login',
      name: 'login',
      component: LoginForm,
    }
  ],
});

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (!store.state.login.isAuthenticated) { // Access the isAuthenticated state from the login module
      next({
        path: '/login',
        query: { redirect: to.fullPath },
      });
    } else {
      next();
    }
  } else {
    next();
  }
});

export default router;
