<template>
    <component
        :is="tag"
        class="btn btn-success"
        :class="{'button-pr': primary, 'button-sec': secondary}"
        :disabled="disabled"
        @click="onClick"
    >
        <i v-if="icon" :class="['faIcon', 'fa', icon]"></i>
        <slot v-if="!noText"></slot>
    </component>
</template>

<script>
export default {
    name: 'IconButton',

    props: {
        icon: {
            type: String,
            default: null
        },
        primary: {
            type: Boolean,
            default: false
        },
        secondary: {
            type: Boolean,
            default: false
        },
        disabled: {
            type: Boolean,
            default: false
        },
        noText: {
            type: Boolean,
            default: false
        },
        small: {
            type: Boolean,
            default: false
        },
        active: {
            type: Boolean,
            default: false
        },
        tag: {
            type: String,
            default: 'button'
        }
    },

    methods: {
        onClick() {
            this.$emit('click');
        }
    }
};
</script>

<style scoped lang="scss">
.button-pr {
    background-color: #16171a !important;
    border-color: #16171a !important;
    color: #fff !important;
    border-radius: 0 !important;
    font-weight: 700 !important;
}

.button-sec {
    background-color: #fff !important;
    border-color: #16171a !important;
    color: #16171a !important;
    border-radius: 0 !important;
    font-weight: 700 !important;
}

.button-sec:hover, .button-pr:hover {
    background-color: #0062ff !important;
    border-color: #0062ff !important;
    color: #fff !important;
    text-decoration: none;
}
</style>
