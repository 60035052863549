<template>
  <div class="reset-password-popup">
    <div class="reset-popup-container">
      <h2>Reset Password</h2>
      <form @submit.prevent="resetPassword">
        <div class="form-group">
          <FieldsRow>
            <input
              type="password"
              id="password"
              v-model="password"
              placeholder="Password*"
              required
            />
          </FieldsRow>
          <FieldsRow>
            <input
              type="password"
              id="confirm-password"
              v-model="confirmPassword"
              placeholder="Confirm Password*"
              required
            />
          </FieldsRow>

          <div class="password-note">
            <span>Your password requires:</span><br />
            <span>At least 8 letters</span><br />
            <span>At least one capital letter</span><br />
            <span>At least one numeric value and one special character</span
            ><br />
            <span>Your new password cannot match the last 3 passwords</span>
          </div>
        </div>
        <button type="button" class="btn button-pr" @click="setNewPassword">
          Set New Password
        </button>
      </form>
    </div>
  </div>
</template>

<script>
import FieldsRow from "@/components/util/FieldsRow.vue";
import { mapActions } from "vuex";

export default {
  name: "ChangePassModal",

  props: {
    visible: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      password: "",
      confirmPassword: "",
    };
  },
  methods: {
    ...mapActions("login", ["changePassword"]),
    async setNewPassword() {
      await this.changePassword({
        password: this.password,
        confirmPassword: this.confirmPassword,
      });
      this.$emit("close");
    },
    validatePassword: function (e) {
      // const regex = ^(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$;
      let char = String.fromCharCode(e.keyCode);
      if (/^[a-zA-Z0-9_.-]+$/.test(char)) {
        return true;
      } else e.preventDefault();
    },
  },
};
</script>

<style scoped>
.reset-password-popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.reset-popup-container {
  width: 600px;
  background-color: #fff;
  padding: 30px;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}

h2 {
  padding-bottom: 20px;
}

input {
  width: 100%;
  padding: 10px;
  margin-bottom: 10px !important;
  background-color: #e8f0fe;
  border: none;
  box-sizing: border-box;
}

input:focus {
  border: 3px solid #bfdeff !important;
}

.form-group {
  padding-top: 15px;
}

.password-note {
  font-size: 14px;
  color: #666;
}

ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

li {
  margin-bottom: 10px;
}

.button-pr {
  background-color: #16171a !important;
  border-color: #16171a !important;
  color: #fff !important;
  border-radius: 0 !important;
  font-weight: 700 !important;
  opacity: 1 !important;
}

.button-pr:hover {
  background-color: #0062ff !important;
  border-color: #0062ff !important;
  color: #fff !important;
  text-decoration: none;
}

.btn.button-pr:disabled {
  opacity: 0.5 !important;
  cursor: not-allowed;
}
</style>
