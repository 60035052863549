<template>
    <div class="tableHeaderCheckboxFilter">
        <checkbox-grid
            :items="outerModel"
            :fields="{ title: 'item', subtitle: 'item', selected: 'selected' }"
            selection-buttons
            small-buttons
        >
        </checkbox-grid>
    </div>
</template>

<script>
import CheckboxGrid from '@/components/util/CheckboxGrid';

export default {
    name: 'TableHeaderCheckboxFilter',

    components: {
        CheckboxGrid
    },

    model: {
        prop: 'outerModel'
    },

    props: {
        outerModel: {
            type: Array,
            required: true,
            validator(value) {
                return value.every(entry => Object.prototype.hasOwnProperty.call(entry, 'item') && Object.prototype.hasOwnProperty.call(entry, 'selected'));
            }
        }
    }
};
</script>
