export default {
    // Returns an array of the given length filled with the given values; if a function is given for a value, the return value of the function is used
    fill(desiredLength, valueOrFunc) {
        const valueIsObject = valueOrFunc !== null && typeof valueOrFunc === 'object';
        const valueIsFunc = typeof valueOrFunc === 'function';

        return Array.from(new Array(desiredLength)).map((v, index) => {
            if (valueIsObject) {
                return JSON.parse(JSON.stringify(valueOrFunc)); // deep copy
            }
            if (valueIsFunc) {
                return valueOrFunc(index);
            }
            return valueOrFunc;
        });
    },

    /* eslint-disable no-empty */
    // Shuffles an array randomly
    shuffle(ar) {
        for (let j, x, i = ar.length; i; j = Math.floor(Math.random() * i), x = ar[--i], ar[i] = ar[j], ar[j] = x){}
        return ar;
    },
    /* eslint-enable no-empty */

    // Sorts an array of objects by key
    sortByKeySimple(array, key) {
        array.sort((a, b) => {
            if (a[key] < b[key]) {
                return -1;
            }
            if (a[key] > b[key]) {
                return 1;
            }
            return 0;
        });
    },

    // Sorts an array of objects alphabetically by key
    sortByKey(array, key) {
        array.sort((a, b) => a[key].toLowerCase().localeCompare(b[key].toLowerCase()));
    },

    // Sort an array of strings respecting locale
    sortLocale(arrayOfStrings) {
        arrayOfStrings.sort((a, b) => a.toLocaleLowerCase().localeCompare(b.toLocaleLowerCase()));
    },

    // Remove duplicate entries from the given array and return a new instance
    unique(array) {
        return Array.from(new Set(array));
    },

    // Groups an array of objects by the values of the given key, e.g. [{ key: 'a' }, { key: 'b' }] => { a: [{ key: 'a' }], b: [{ key: 'b' }] }
    groupByKey(key, array) {
        return array.reduce((agg, item) => {
            agg[item[key]] = agg[item[key]] || [];
            agg[item[key]].push(item);
            return agg;
        }, Object.create(null));
    },

    // Transposes a matrix, e.g. from [[1, 2], [3, 4], [5, 6]] to [[1, 3, 5], [2, 4, 6]]
    transpose(matrix) {
        return matrix.reduce((agg, row) => row.map((_, i) => [...(agg[i] || []), row[i]]), []);
    },

    // Pick a random element from the given array
    randomElement(array) {
        return array[Math.floor(Math.random() * array.length)];
    },

    // Flatten an array of arrays to a single array
    flatten(arrays) {
        return [].concat(...arrays);
    }
};
