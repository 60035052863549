import { CockpitUsersApi } from "@/server/index";
import { COLUMNS } from "@/components/cockpitUsers/config/tableColumns";
import TableColumnService from "@/components/util/paginatedTable/tableColumnService";
import CockpitUserFactory from "@/components/cockpitUsers/CockpitUserFactory";

const initialState = {
  users: [],
  filters: {},
  tableHeaders: [],
};
let counter = 0;
export default {
  namespaced: true,
  state: initialState,
  getters: {
    users: (state, rootState, getters, rootGetters) => {
      return state.users.map((user) => {
        const rentalPartner = rootGetters[
          "rentalPartners/getRentalPartnerByBusinessId"
        ](user.rentalPartnerBusinessId);

        return {
          ...user,
          rentalPartnerDealerId: rentalPartner ? rentalPartner.dealerId : "",
        };
      });
    },
    tableHeaders: (state) => state.tableHeaders,
    selectedTableHeaders: (state) =>
      state.tableHeaders.filter((header) => header.selected),
    tableFilters: (state) => state.filters,
  },
  actions: {
    async getUsers({ commit, dispatch }) {
      const { data } = await CockpitUsersApi.getUsers();
      commit("SET_COCKPIT_USERS", data);

      dispatch("setFilters");
    },

    async createUser({ commit, dispatch }, userData) {
      const dataToSend = CockpitUserFactory.prepareForSending(userData);

      const { data } = await CockpitUsersApi.createUser(dataToSend);
      commit("ADD_COCKPIT_USER", data);

      dispatch("setFilters");
    },

    async updateUser({ commit, dispatch }, userData) {
      const dataToSend = CockpitUserFactory.prepareForSending(userData);
      await CockpitUsersApi.updateUser(dataToSend);
      commit("UPDATE_COCKPIT_USER", userData);
      dispatch("setFilters");
    },

    setFilters({ commit, state }) {
      const filters = TableColumnService.buildFiltersForTableData(
        COLUMNS,
        state.users
      );
      commit("SET_FILTERS", filters);
    },

    addFilter({ commit, state }, filter) {
      commit("SET_FILTERS", {
        ...state.filters,
        ...filter,
      });
    },

    setTableColumns({ commit }, columns) {
      let getInitialUserColumns;
      if (counter == 0) {
        getInitialUserColumns = localStorage.getItem("cockpit-user-columns")
          ? JSON.parse(localStorage.getItem("cockpit-user-columns"))
          : columns;
      } else {
        getInitialUserColumns = columns;
      }

      commit("SET_TABLE_COLUMNS", getInitialUserColumns);
      counter++;
    },
  },
  mutations: {
    SET_COCKPIT_USERS(state, users) {
      state.users = users;
    },
    SET_FILTERS(state, filters) {
      state.filters = filters;
    },
    SET_TABLE_COLUMNS(state, data) {
      state.tableHeaders = data;
    },
    ADD_COCKPIT_USER(state, data) {
      state.users.push(data);
    },

    UPDATE_COCKPIT_USER(state, data) {
      const indexToUpdate = state.users.findIndex(
        (entry) => entry.businessId === data.businessId
      );
      state.users[indexToUpdate] = data;
    },
  },
};
