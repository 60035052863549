import "@/assets/styles/main.scss";
import "font-awesome/css/font-awesome.css";

import { createApp } from "vue";
import { importBootstrap, importMoment } from "@/assets/vendor";
import App from "./App.vue";
import router from "@/router";
import store from "@/store";
import VueEventer from "vue-eventer";

importBootstrap();
importMoment();

async function initialize() {

  const app = createApp(App).use(router).use(store);

  app.config.globalProperties.$eventBus = new VueEventer();
  app.config.productionTip = false;
  app.mount("#app");
}

initialize();
