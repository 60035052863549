import { COLUMN_TYPE } from '@/components/util/paginatedTable/columnTypes';

export const COLUMNS = {
    username: {
        type: COLUMN_TYPE.arbitrary,
        title: 'Username',
        initial: true
    },
    email: {
        type: COLUMN_TYPE.arbitrary,
        title: 'E-Mail',
        initial: true
    },
    enabled: {
        type: COLUMN_TYPE.enum,
        title: 'Enabled',
        initial: true
    },
    userGroupType: {
        type: COLUMN_TYPE.enum,
        title: 'User Group Type',
        initial: true
    },
    action: {
        title: 'Action',
        initial: true
    }
};
