import { createStore } from 'vuex';
import notifications from '@/store/modules/notifications';
import permissions from '@/store/modules/permissions';
import cockpitUsers from '@/store/modules/cockpitUsers';
import rentalPartners from '@/store/modules/rentalPartners';
import login from '@/store/modules/login';
import promotions from '@/store/modules/promotions';
import dmsCertificates from '@/store/modules/dmsCertificates';
import users from '@/store/modules/users';

export default createStore({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    cockpitUsers,
    rentalPartners,
    notifications,
    permissions,
    login,
    promotions,
    dmsCertificates,
    users
  }
});
