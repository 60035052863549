<template>
  <div>
    <div class="row displayButtons">
      <table-column-selection
        :table-headers="tableHeaders"
        @selection-changed="onSelectionChanged"
      ></table-column-selection>
      <button class="btn btn-success mr-10 button-pr" @click="onAddButtonClick">
        <i class="fa fa-plus"></i> Add Cockpit User
      </button>
    </div>

    <br />

    <div class="row">
      <cockpit-users-table
        @edit-button-clicked="onEditButtonClick"
        :users="users"
        class="col-md-12"
        style="padding-left: 0px; padding-right: 0px"
      ></cockpit-users-table>
    </div>

    <cockpit-user-edit-modal
      ref="editModal"
      :user="selectedEntity"
      :is-updating="isUpdating"
    ></cockpit-user-edit-modal>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import CockpitUsersTable from "@/components/cockpitUsers/CockpitUsersTable";
import CockpitUserEditModal from "@/components/cockpitUsers/CockpitUserEditModal";
import TableColumnSelection from "@/components/util/paginatedTable/TableColumnSelection";
import { COLUMNS } from "@/components/cockpitUsers/config/tableColumns";
import TableColumnService from "@/components/util/paginatedTable/tableColumnService";

export default {
  name: "CockpitUsersSetup",
  data() {
    return {
      selectedEntity: null,
    };
  },
  props: {
    showAddUserPopup: {
      type: Boolean,
    },
  },
  components: {
    TableColumnSelection,
    CockpitUserEditModal,
    CockpitUsersTable,
  },
  computed: {
    ...mapGetters("cockpitUsers", ["users", "tableHeaders", "tableFilters"]),
    isUpdating() {
      return this.selectedEntity !== null;
    },
  },
  mounted() {
    this.setTableColumns();
  },
  methods: {
    setTableColumns() {
      const columns = TableColumnService.getInitialTableHeaders(COLUMNS);

      this.$store.dispatch("cockpitUsers/setTableColumns", columns);
    },

    onAddButtonClick() {
      this.selectedEntity = null;
      this.$eventBus.$emit("openUserEditModal");
    },

    onEditButtonClick(user) {
      this.selectedEntity = user;
      this.$eventBus.$emit("openUserEditModal", user);
    },

    onSelectionChanged(columns) {
      this.$store.dispatch("users/setTableColumns", columns);
      localStorage.setItem(
        "cockpit-user-columns",
        JSON.stringify(this.tableHeaders)
      );
    },
  },
};
</script>

<style lang="scss">
.displayButtons {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
}

.mr-10 {
  margin-right: 10px !important;
}

.button-pr {
  background-color: #16171a !important;
  border-color: #16171a !important;
  color: #fff !important;
  border-radius: 0 !important;
  font-weight: 700 !important;
}

.button-pr:hover {
  background-color: #0062ff !important;
  border-color: #0062ff !important;
  color: #fff !important;
  text-decoration: none;
}
</style>
