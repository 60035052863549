<template>
    <div class="tableHeaderDateRangeFilter input-group">
        <!-- <div><input type="date" :max="maxDate" :min="minDate" class="form-control" v-model="model.startDate" /></div> -->
        <!-- <div><input type="date" :max="maxDate" :min="minDate" class="form-control" v-model="model.endDate" /></div> -->
        <span class="input-group-addon">
            <i class="fa fa-times" @click="clear()" aria-hidden="true"></i>
        </span>
    </div>
</template>

<script>
export default {
    name: 'TableHeaderDateRangeFilter',

    model: {
        prop: 'outerModel'
    },

    props: {
        outerModel: {
            type: Object,
            required: true
        }
    },

    computed: {
        model: {
            // Sync inner model with outer model
            get() {
                return this.outerModel;
            },
            set(value) {
                this.$emit('input', value);
                this.$eventBus.$emit('selection-changed-filter', value);
            }
        },
        minDate() {
            let year = new Date().getFullYear();
            return `${year}-01-01`;
        },
        maxDate() {
            let year = new Date().getFullYear();
            return `${year}-12-31`;
        }
    },

    methods: {
        clear: function() {
            this.model.startDate = '';
            this.model.endDate = '';
        }
    }
};
</script>

<style lang="scss" scoped>
.tableHeaderDateRangeFilter {
    width: 360px;

    div {
        width: 160px;
        display: inline-block;
    }
}
</style>
