import Util from '@/util';
import CURRENCIES from '@/util/currencies';

const TYPES = {
    BMW_CONTRACT_DEALER: 'Contract Dealer',
    BMW_SUBSIDIARY: ' Subsidiary',
    HOTEL: 'Hotel',
    EXTERNAL_RENTAL_PARTNER: 'External Rental Partner',
    BMW_SERVICE_PARTNER: 'Service Partner',
    NSC_FLEET_DEALER: 'NSC'
};

const COUNTRIES = {
    AL: 'Albania',
    DZ: 'Algeria',
    AR: 'Argentina',
    AU: 'Australia',
    AT: 'Austria',
    AZ: 'Azerbaijan',
    BH: 'Bahrain',
    BY: 'Belarus',
    BE: 'Belgium',
    BO: 'Bolivia',
    BA: 'Bosnia-Herzegovina',
    BR: 'Brazil',
    BN: 'Brunei',
    BG: 'Bulgaria',
    CA: 'Canada',
    CL: 'Chile',
    CN: 'China',
    CO: 'Colombia',
    CR: 'Costa Rica',
    HR: 'Croatia',
    CY: 'Cyprus',
    CZ: 'Czech Republic',
    DK: 'Denmark',
    DO: 'Dominican Republic',
    EC: 'Ecuador',
    EG: 'Egypt',
    SV: 'El Salvador',
    EE: 'Estonia',
    FO: 'Faroe Islands',
    FI: 'Finland',
    FR: 'France',
    DE: 'Germany',
    GB: 'Great Britain',
    GR: 'Greece',
    GP: 'Guadeloupe',
    GT: 'Guatemala',
    HK: 'Hong Kong',
    HU: 'Hungary',
    IC: 'Iceland',
    V2: 'Importers',
    IN: 'India',
    ID: 'Indonesia',
    IE: 'Ireland',
    IL: 'Israel',
    IT: 'Italy',
    JP: 'Japan',
    JO: 'Jordan',
    KZ: 'Kazakhstan',
    KE: 'Kenya',
    KW: 'Kuwait',
    RE: 'La Reunion',
    LV: 'Latvia',
    LB: 'Lebanon',
    LT: 'Lithuania',
    LU: 'Luxembourg',
    MO: 'Macao',
    MK: 'Macedonia',
    MY: 'Malaysia',
    MT: 'Malta',
    MQ: 'Martinique',
    MU: 'Mauritius',
    MX: 'Mexico',
    MD: 'Moldova (Republic Moldova)',
    ME: 'Montenegro',
    MA: 'Morocco',
    NL: 'Netherlands',
    NC: 'New Caledonia',
    NZ: 'New Zealand',
    NG: 'Nigeria',
    NO: 'Norway',
    OM: 'Oman',
    PA: 'Panama',
    PY: 'Paraguay',
    PE: 'Peru',
    PH: 'Phillippines',
    PL: 'Poland',
    PT: 'Portugal',
    QA: 'Qatar',
    RO: 'Romania',
    RU: 'Russia',
    SA: 'Saudi Arabia',
    RS: 'Serbia',
    SG: 'Singapore',
    SK: 'Slovakia',
    SI: 'Slovenia',
    ZA: 'South Africa',
    KR: 'South Korea',
    ES: 'Spain',
    SE: 'Sweden',
    CH: 'Switzerland',
    PF: 'Tahiti',
    TW: 'Taiwan',
    TH: 'Thailand',
    TN: 'Tunisia',
    TR: 'Turkey',
    AE: 'United Arab Emirates',
    UY: 'Uruguay',
    US: 'USA',
    VN: 'Vietnam'
};

const HUBS = {
    EMEA: 'EMEA',
    US: 'US',
    CN: 'CN',
    APAC: 'APAC'
};

const REGIONS = {
    V1: 'V1',
    V2: 'V2',
    V2_BE: 'V2-BE',
    V2_CS: 'V2-CS',
    V2_EU: 'V2-EU',
    V2_Nordics: 'V2-Nordics',
    V2_OE: 'V2-OE',
    V2_UK: 'V2-UK',
    V3: 'V3',
    V3_KA: 'V3-KA',
    V3_MO: 'V3-MO',
    V3_OA: 'V3-OA',
    V4: 'V4',
    V4_LA: 'V4-LA'
};
const PAYSTATUS = {
    DISABLED: 'DISABLED',
    ENABLED: 'ENABLED',
    ACTIVE: 'ACTIVE'
};
const DISTANCE_UNIT = {
    KM: 'KM',
    MILE: 'MILE'
};
const OPTIONS = {
    TYPES,
    COUNTRIES,
    HUBS,
    REGIONS,
    CURRENCIES,
    PAYSTATUS,
    DISTANCE_UNIT
};

const DEFAULT_RENTAL_PARTNER = {
    type: 'BMW_CONTRACT_DEALER',
    hub: HUBS.EMEA,
    region: 'V1',
    country: 'DE',
    timezone: 'Europe/Berlin',
    languageCode: 'de',
    currency: 'EUR',
    companyName: 'Saurabh_Company',
    registrationKind: 'Noraml',
    enabled: true,
    bikeVisibility: false,
    bmwPayStatus: PAYSTATUS.DISABLED,
    distanceUnit: DISTANCE_UNIT.KM
};

function create() {
    return DEFAULT_RENTAL_PARTNER;
}

function prepareForSending(configToSend) {
    const cloned = Util.object.clone(configToSend);
    return cloned;
}

export default {
    create,
    prepareForSending,

    getOptions: () => OPTIONS
};
