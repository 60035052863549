<template>
  <div>
    <h1 style="font-weight: 700 !important;" class="Heading">RAR COCKPIT LOGIN</h1>
  </div>
  <div class="form-container">
    <div class="login-container">
      <form @submit.prevent="handleLogin">
        <div class="form-group">
          <input
            type="text"
            id="username"
            v-model="username"
            placeholder="User Name*"
            required
          />
        </div>
        <div class="form-group">
          <input
            type="password"
            id="password"
            v-model="password"
            placeholder="Password*"
            required
          />
        </div>
        <div>
          <button type="submit">LOGIN</button>
          <div style="cursor: pointer;" class="links">
            <span @click="openOTPModal" style="color: #007bff"
              >Forgot Password?</span
            >
          </div>
        </div>
      </form>
    </div>
  </div>
  <forgot-pass-modal
    :visible="isForgotPasswordModalVisible"
    @close="closeOTPModal"
  ></forgot-pass-modal>
</template>

<script>
import ForgotPassModal from "@/components/core/login/ForgotPassModal.vue";
import { mapActions, mapGetters } from "vuex";
import Notifications from "@/util/notifications";
import store from "@/store";

export default {
  name: "LoginForm",
  components: {
    ForgotPassModal,
  },
  data() {
    return {
      username: "",
      password: "",
      isForgotPasswordModalVisible: false,
    };
  },
  methods: {
    ...mapActions("login", ["login", "logout"]),
    ...mapGetters("login", ["isAuthenticated"]),
    async handleLogin() {
      try {
        await this.login({ username: this.username, password: this.password });
        await this.callingInitialApiForData();
        const redirectPath = "/rentalPartners";
        this.$router.push(redirectPath);
      } catch (error) {
        Notifications.showError("Invalid username/password", true, false);
      }
    },
    openOTPModal() {
      this.isForgotPasswordModalVisible = true;
    },
    closeOTPModal() {
      this.isForgotPasswordModalVisible = false;
    },
    async callingInitialApiForData() {
      if (this.isAuthenticated) {
        try {
          await store.dispatch("permissions/loadPermissions");

          await Promise.all([
            store.dispatch("rentalPartners/getRentalPartners"),
            store.dispatch("users/getUsers"),
            store.dispatch("promotions/getPromotions"),
            store.dispatch("promotions/getPromotionsValues"),
          ]);
          if (localStorage.getItem("cockpitUserInfo")) {
            store.dispatch("cockpitUsers/getUsers");
          }
        } catch {
          this.logout();
          this.$router.push("/login");
        }
      }
    },
  },
};
</script>

<style scoped>
.login-container {
  margin: 0 auto;
  padding: 30px;
  background-color: #e6e6e6;
  max-width: 50%;
}

.form-container {
  max-width: 100%;
  margin: 0 auto;
  padding: 30px;
  background-color: #e6e6e6;
  text-align: left;
}

.Heading {
  margin-bottom: 20px;
  font-size: 50px !important;
  text-align: center;
  padding: 10px;
  font-family: "Noto Sans", sans-serif !important;
  font-weight: 700px !important;
}

.form-group {
  margin-bottom: 15px;
}

label {
  display: block;
  margin-bottom: 5px;
}

input {
  width: 100%;
  padding: 10px;
  background-color: #e8f0fe;
  border: none;
  box-sizing: border-box;
}

button {
  width: 20%;
  padding: 10px;
  background-color: black;
  color: white !important;
  border: none;
  cursor: pointer;
  text-align: center;
}

button:hover {
  background-color: #007bff;
}

.links {
  margin-top: 10px;
  text-align: right;
}

.links a {
  color: #007bff;
  text-decoration: none;
}

.links a:hover {
  text-decoration: underline;
}
</style>
