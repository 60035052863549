import axios from "axios";

async function init() {
  const token = localStorage.getItem('idToken');

  axios.interceptors.request.use(
    (config) => {
      return {
        ...config,
        headers: {
          IDToken: token,
          ...config.headers,
        },
      };
    },
    (error) => Promise.reject(error)
  );

  axios.interceptors.response.use(
    (response) => {
      return response;
    },
    function (error) {
      return Promise.reject(error.response);
    }
  );
}

init();

export default {
    get: axios.get,
    post: axios.post,
    delete: axios.delete,
    init
};
