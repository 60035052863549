import Ajax from '@/util/ajax';
import Api from '@/config/api';

const BASE_URL = Api.getServiceUrl('userProfileService');
const API_PATH = '/api/cockpit';

function getUrl() {
    return BASE_URL + API_PATH;
}
export default {
    getUsers() {
        const url = `${getUrl()}/user-profiles`;
        return Ajax.get(url);
    },
    createUser(data) {
        const url = `${getUrl()}/user-profiles`;
        return Ajax.post(url, data);
    },
    updateUser(businessId, data) {
        const url = `${getUrl()}/user-profiles/${businessId}`;
        return Ajax.post(url, data);
    }
};
