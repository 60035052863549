import { COLUMN_TYPE } from '@/components/util/paginatedTable/columnTypes';

export const COLUMNS = {
    businessId: {
        type: COLUMN_TYPE.arbitrary,
        title: 'ID',
        initial: true
    },
    username: {
        type: COLUMN_TYPE.arbitrary,
        title: 'Username',
        initial: true
    },
    name: {
        type: COLUMN_TYPE.arbitrary,
        title: 'First Name',
        initial: false
    },
    surname: {
        type: COLUMN_TYPE.arbitrary,
        title: 'Last Name',
        initial: false
    },
    email: {
        type: COLUMN_TYPE.arbitrary,
        title: 'E-Mail',
        initial: true
    },
    gender: {
        type: COLUMN_TYPE.enum,
        title: 'Gender',
        initial: true
    },
    country: {
        type: COLUMN_TYPE.enum,
        title: 'Country',
        initial: true
    },
    isoLanguageCode: {
        type: COLUMN_TYPE.enum,
        title: 'Language',
        initial: true
    },
    addedOn: {
        type: COLUMN_TYPE.arbitrary,
        title: 'Added on',
        initial: true
    },
    enabled: {
        type: COLUMN_TYPE.enum,
        title: 'Enabled',
        initial: true
    },
    userType: {
        type: COLUMN_TYPE.enum,
        title: 'User Type',
        initial: true
    },
    rentalPartnerDealerId: {
        type: COLUMN_TYPE.arbitrary,
        title: 'Rental Partner BUNO',
        initial: true
    },
    app: {
        type: COLUMN_TYPE.enum,
        title: 'App',
        initial: true
    },
    action: {
        title: 'Action',
        initial: true
    }
};
