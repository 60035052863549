import Util from '@/util';

const GENDERS = {
    M: 'Male',
    W: 'Female',
    D: 'Other'
};

const USER_TYPES = {
    SUPER_USER: 'Super User',
    ADMIN: 'Admin',
    USER: 'Operative User',
    TECHNICAL_USER: 'DMS Technical User'
};

const DEFAULT_USER = {
    rentalPartnerBusinessId: '',
    username: '',
    email: '',
    gender: 'M',
    isoLanguageCode: 'de',
    enabled: true,
    userType: 'USER',
    app: true,
    errorMessageFromAppCenter: '',
    errorMessageFromTestFlight: ''
};

const COUNTRIES = {
    AL: 'Albania',
    DZ: 'Algeria',
    AR: 'Argentina',
    AU: 'Australia',
    AT: 'Austria',
    AZ: 'Azerbaijan',
    BH: 'Bahrain',
    BY: 'Belarus',
    BE: 'Belgium',
    BO: 'Bolivia',
    BA: 'Bosnia-Herzegovina',
    BR: 'Brazil',
    BN: 'Brunei',
    BG: 'Bulgaria',
    CA: 'Canada',
    CL: 'Chile',
    CN: 'China',
    CO: 'Colombia',
    CR: 'Costa Rica',
    HR: 'Croatia',
    CY: 'Cyprus',
    CZ: 'Czech Republic',
    DK: 'Denmark',
    DO: 'Dominican Republic',
    EC: 'Ecuador',
    EG: 'Egypt',
    SV: 'El Salvador',
    EE: 'Estonia',
    FO: 'Faroe Islands',
    FI: 'Finland',
    FR: 'France',
    DE: 'Germany',
    GB: 'Great Britain',
    GR: 'Greece',
    GP: 'Guadeloupe',
    GT: 'Guatemala',
    HK: 'Hong Kong',
    HU: 'Hungary',
    IC: 'Iceland',
    V2: 'Importers',
    IN: 'India',
    ID: 'Indonesia',
    IE: 'Ireland',
    IL: 'Israel',
    IT: 'Italy',
    JP: 'Japan',
    JO: 'Jordan',
    KZ: 'Kazakhstan',
    KE: 'Kenya',
    KW: 'Kuwait',
    RE: 'La Reunion',
    LV: 'Latvia',
    LB: 'Lebanon',
    LT: 'Lithuania',
    LU: 'Luxembourg',
    MO: 'Macao',
    MK: 'Macedonia',
    MY: 'Malaysia',
    MT: 'Malta',
    MQ: 'Martinique',
    MU: 'Mauritius',
    MX: 'Mexico',
    MD: 'Moldova (Republic Moldova)',
    ME: 'Montenegro',
    MA: 'Morocco',
    NA: 'Not Applicable',
    NL: 'Netherlands',
    NC: 'New Caledonia',
    NZ: 'New Zealand',
    NG: 'Nigeria',
    NO: 'Norway',
    OM: 'Oman',
    PA: 'Panama',
    PY: 'Paraguay',
    PE: 'Peru',
    PH: 'Phillippines',
    PL: 'Poland',
    PT: 'Portugal',
    QA: 'Qatar',
    RO: 'Romania',
    RU: 'Russia',
    SA: 'Saudi Arabia',
    RS: 'Serbia',
    SG: 'Singapore',
    SK: 'Slovakia',
    SI: 'Slovenia',
    ZA: 'South Africa',
    KR: 'South Korea',
    ES: 'Spain',
    SE: 'Sweden',
    CH: 'Switzerland',
    PF: 'Tahiti',
    TW: 'Taiwan',
    TH: 'Thailand',
    TN: 'Tunisia',
    TR: 'Turkey',
    AE: 'United Arab Emirates',
    UY: 'Uruguay',
    US: 'USA',
    VN: 'Vietnam',
    '': ''
};

const OPTIONS = {
    GENDERS,
    COUNTRIES,
    USER_TYPES
};

function create(withData = {}) {
    return {
        ...DEFAULT_USER,
        ...withData
    };
}

function prepareForSending(configToSend) {
    return Util.object.clone(configToSend);
}

export default {
    create,
    prepareForSending,

    getOptions: () => OPTIONS
};
