<template>
    <div class="tableHeaderSearchFilter input-group">
        <input type="text" class="form-control" placeholder="Search" v-model="model" />
        <span class="input-group-addon">
            <i class="fa fa-search"></i>
        </span>
    </div>
</template>

<script>
export default {
    name: 'TableHeaderSearchFilter',

    model: {
        prop: 'outerModel'
    },

    props: {
        outerModel: {
            type: String,
            required: true
        }
    },

    computed: {
        model: {
            // Sync inner model with outer model
            get() {
                return this.outerModel;
            },
            set(value) {
                this.$emit('input', value);
                this.$eventBus.$emit('selection-changed-filter', value);
            }
        }
    }
};
</script>

<style lang="scss">
.tableHeaderSearchFilter {
    width: 300px;
}
</style>
