function isObject(item) {
    return item && typeof item === 'object' && !Array.isArray(item) && !(item instanceof Date);
}

function deepMerge(target, source) {
    const output = Object.assign({}, target);
    if (isObject(target) && isObject(source)) {
        Object.keys(source).forEach(key => {
            if (isObject(source[key])) {
                if (!(key in target)) {
                    Object.assign(output, { [key]: source[key] });
                } else {
                    output[key] = deepMerge(target[key], source[key]);
                }
            } else {
                Object.assign(output, { [key]: source[key] });
            }
        });
    }
    return output;
}

function deepFreeze(obj) {
    if (!obj) {
        return obj;
    }
    if (Array.isArray(obj)) {
        Object.freeze(obj);
        for (const entry of obj) {
            deepFreeze(entry);
        }
    } else if (typeof obj === 'object') {
        Object.freeze(obj);
        for (const value of Object.values(obj)) {
            deepFreeze(value);
        }
    }
    return obj;
}

/** Deep-clones the given object */
export function clone(obj) {
    try {
        return JSON.parse(JSON.stringify(obj));
    } catch (e) {
        throw 'Object cannot be deep-cloned because it might be cyclical or contain non-clonable properties.';
    }
}

export default {
    // Recursively freeze a nested object or array; use carefully
    deepFreeze,

    // Deep-merges the given objects; returns a new object instance
    // WARNING: Might not work correctly for class instances (i.e. new MyClass())
    merge(...objs) {
        let result = {};
        for (const obj of objs) {
            result = deepMerge(result, obj);
        }
        return result;
    },

    clone,

    // Creates an object with all the array entries as keys
    fromArray(array, value = null) {
        const obj = {};
        array.forEach(item => (obj[item] = value));
        return obj;
    },

    // Swaps keys with values in the given object, e.g. { a: 'b' } => { b: 'a' }
    invert(obj) {
        const resultObj = {};
        for (const [key, value] of Object.entries(obj)) {
            if (typeof value !== 'string') {
                throw 'Cannot invert object because one of the values is not of type String: ' + value;
            }
            resultObj[value] = key;
        }
        return resultObj;
    },

    // Sets a value in a nested object specified by the given path
    deepSet(obj, path, value) {
        const pathParts = Array.isArray(path) ? path : path.split('.');
        let currentObj = obj;

        while (pathParts.length > 1) {
            const prop = pathParts.shift();
            if (!Object.prototype.hasOwnProperty.call(currentObj, prop)) {
                currentObj[prop] = {};
            }
            currentObj = currentObj[prop];
        }

        currentObj[pathParts[0]] = value;
        return obj;
    },

    // Makes the given property in the given object reactive.
    // When the property is changed, the given onSet callback is called.
    reactiveProp(obj, prop, onSet) {
        let value = obj[prop];

        Object.defineProperty(obj, prop, {
            configurable: true,
            enumerable: true,
            get() {
                return value;
            },
            set(newValue) {
                value = newValue;
                onSet.call(this);
            }
        });
    },

    // Makes the given reactive property (created by reactiveProp()) normal/"unreactive" again
    unreactiveProp(obj, prop) {
        const value = obj[prop];
        delete obj[prop];
        obj[prop] = value;
    },

    isEmpty(obj) {
        return Object.keys(obj).length === 0 && obj.constructor === Object;
    }
};
