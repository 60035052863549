import Ajax from "@/util/ajax";
import Api from "@/config/api";

const BASE_URL = Api.getServiceUrl("userProfileService");
const API_PATH = "/api";

function getUrl() {
  return BASE_URL + API_PATH;
}
export default {
  getUsers() {
    const url = `${getUrl()}/cockpit-user`;
    return Ajax.get(url);
  },
  createUser(data) {
    const url = `${getUrl()}/cockpit-user/create`;
    return Ajax.post(url, data);
  },
  updateUser(data) {
    const { cockpitUserBusinessId } = data;
    const url = `${getUrl()}/cockpit-user/update/${cockpitUserBusinessId}`;
    return Ajax.post(url, data);
  },
};
