import array from './array';
import object from './object';
import string from './string';

export default {
    object,
    array,
    string
};

export * from './object';
