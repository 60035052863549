<template>
    <splash-screen class="home">
        <cockpit-title large></cockpit-title>
    </splash-screen>
    <change-pass-modal v-if="showResetPasswordModal" @close="showResetPasswordModal = false" />
</template>

<script>
import CockpitTitle from '@/components/core/CockpitTitle';
import SplashScreen from '@/components/core/splash/SplashScreen';
import ChangePassModal from '@/components/core/login/ChangePassModal.vue';
import { mapGetters } from 'vuex';

export default {
    // eslint-disable-next-line vue/multi-word-component-names
    name: 'Home',

    components: {
        CockpitTitle,
        SplashScreen,
        ChangePassModal
    },

    data() {
        return {
            cockpitUserDetail: this.cockpitUserDetails,
        }
    },

    computed: {
    ...mapGetters('login',['cockpitUserDetails']),

    showResetPasswordModal() {
      return this.cockpitUserDetails && this.cockpitUserDetails.wasLastSuccessfulLoginOtp;
    },
    },
};
</script>

<style lang="scss">
.home {
    h2 {
        font-weight: normal;
        font-size: 42px;
        margin: -25px 0 5px;
    }
}

// @media (max-width: $screen-lg) {
//     .home {
//         h2 {
//             font-size: 35px;
//         }
//     }
// }
</style>
