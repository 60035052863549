import Environment from '@/util/environment';
import * as IFrameBridge from '@odm-operations-tooling/iframe-bridge';

async function getDataFromParentApp(key) {
    return await IFrameBridge.getDataFromParent(key);
}

async function getRawToken() {
    try {
        return await getDataFromParentApp('ODM_COCKPIT_TOKEN');
    } catch (e) {
        return Promise.resolve(null);
    }
}

async function getPermission() {
    try {
        return await getDataFromParentApp('PERMISSIONS');
    } catch (e) {
        if (Environment.isLocal()) {
            return Promise.resolve([
                'rentalpartner_manage',
                'rentalpartner_account_manage',
                'rar_promotions_manage',
                'rentalpartner_account_read'
            ]);
        }
        return Promise.resolve([]);
    }
}

export default {
    getRawToken,
    getPermission
};
