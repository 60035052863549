import { COLUMN_TYPE } from '@/components/util/paginatedTable/columnTypes';

export const COLUMNS = {
    Status: {
        type: COLUMN_TYPE.custom,
        title: 'status',
        initial: true
    },
    dealerId: {
        type: COLUMN_TYPE.arbitraryEnum || COLUMN_TYPE.arbitraryEqual,
        title: 'BUNO',
        initial: true
    },
    name: {
        type: COLUMN_TYPE.arbitrary,
        title: 'Name',
        initial: true
    },
    notificationEmails: {
        type: COLUMN_TYPE.arbitrary,
        title: 'Notification Emails',
        initial: true
    },
    certificateExpirationDate: {
        type: COLUMN_TYPE.dateRange,
        title: 'Certificate Expiration Timestamp',
        initial: true
    },
    action: {
        sortingDisabled: true,
        title: 'Action',
        initial: true
    }
};
