<template>
    <div>
        <paginated-table
            :items="users"
            :headers="tableHeaders"
            :filters="tableFilters"
            :cell-renderer="cellRenderer"
            :sorting-disabled-for="['action']"
            :permissions="hasRentalPartnerReadPermission"
            page-size="25"
            header-no-wrap
            min-height="200px"
        >
            <template #cellSlot="props">
                <div v-if="props.columnKey === 'action'">
                    <button class="btn btn-default btn-sm" title="Update User" @click="onEditButtonClick(props.item)">
                        <i class="fa fa-edit"></i>
                    </button>
                </div>
            </template>
        </paginated-table>
    </div>
</template>
<script>
import { mapGetters } from 'vuex';
import PaginatedTable from '@/components/util/paginatedTable/PaginatedTable';

export default {
    name: 'CockpitUsersTable',
    components: { PaginatedTable },
    props: {
        users: {
            type: Array,
            default: () => []
        }
    },
    computed: {
        ...mapGetters('cockpitUsers', ['selectedTableHeaders', 'tableFilters']),
        ...mapGetters('permissions', ['hasRentalPartnerReadPermission']),
        tableHeaders() {
            return this.selectedTableHeaders.reduce((agg, { key, title }) => {
                agg[key] = title;
                return agg;
            }, {});
        }
    },
    methods: {
        cellRenderer(row, key) {
            const item = row[key];
            switch (key) {
                case 'enabled':
                case 'app':
                case 'active':
                    return `<i class="fa ${item ? 'fa-check' : 'fa-close'}"></i>`;
                case 'action':
                    return '';
                default:
                    return item;
            }
        },
        onEditButtonClick(rentalPartner) {
            this.$emit('edit-button-clicked', rentalPartner);
        }
    }
};
</script>
