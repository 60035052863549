import environment from '@/util/environment';

const SERVICES = {
    catalogueService: 'rar-catalogue-service',
    userProfileService: 'rar-user-profile-service',
    promotionService: 'rar-promotion-service',
    dmsService: 'rar-dms-service',
    userAccountService: 'rar-user-account-service',
    authenticationService: 'rar-authentication-service',
};

export default {
    getServiceUrl: serviceName => {
        const ENV = environment.getEnvironment();
        if (!Object.prototype.hasOwnProperty.call(SERVICES, serviceName)) {
            throw new Error('No service configured.');
        }
        if (serviceName == 'dmsService' || serviceName == 'authenticationService') {
            return `https://rentaride.${environment.getEnvironment()}.odm.bmw-fleet.net/api/${SERVICES[serviceName]}`;
        } else {
            return `https://${SERVICES[serviceName]}.${environment.getEnvironment()}.odm.bmw-fleet.net`;
        }
    }
};
