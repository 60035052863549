import { reactive } from 'vue';
import { UsersApi } from '@/server/index';
import { COLUMNS } from '@/components/users/config/tableColumns';
import TableColumnService from '@/components/util/paginatedTable/tableColumnService';
import UserFactory from '@/components/users/userFactory';

const initialState = {
    users: [],
    filters: {},
    tableHeaders: []
};
let counter = 0;
export default {
    namespaced: true,
    state: reactive(initialState),
    getters: {
        users: (state, rootState, getters, rootGetters) => {
            return state.users.map(user => {
                const rentalPartner = rootGetters['rentalPartners/getRentalPartnerByBusinessId'](
                    user.rentalPartnerBusinessId
                );

                return {
                    ...user,
                    rentalPartnerDealerId: rentalPartner ? rentalPartner.dealerId : ''
                };
            });
        },
        tableHeaders: state => state.tableHeaders,
        selectedTableHeaders: state => state.tableHeaders.filter(header => header.selected),
        tableFilters: state => state.filters,
        getUsersByRentalPartnerBusinessId: state => businessId => {
            return state.users.filter(user => user.rentalPartnerBusinessId === businessId);
        }
    },
    actions: {
        async getUsers({ commit, dispatch }) {
            const { data } = await UsersApi.getUsers();
            commit('SET_USERS', data);

            dispatch('setFilters');
        },

        async createUser({ commit, dispatch }, userData) {
            const dataToSend = UserFactory.prepareForSending(userData);

            const { data } = await UsersApi.createUser(dataToSend);
            commit('ADD_USER', data);

            dispatch('setFilters');
        },

        async updateUser({ commit, dispatch }, userData) {
            const { businessId } = userData;
            const dataToSend = UserFactory.prepareForSending(userData);
            await UsersApi.updateUser(businessId, dataToSend);
            commit('UPDATE_USER', userData);
            dispatch('setFilters');
        },

        setFilters({ commit, state }) {
            const filters = TableColumnService.buildFiltersForTableData(COLUMNS, state.users);
            commit('SET_FILTERS', filters);
        },

        addFilter({ commit, state }, filter) {
            commit('SET_FILTERS', {
                ...state.filters,
                ...filter
            });
        },

        setTableColumns({ commit }, columns) {
            let getInitialUserColumns;
            if (counter == 0) {
                getInitialUserColumns = localStorage.getItem('user-columns')
                    ? JSON.parse(localStorage.getItem('user-columns'))
                    : columns;
            } else {
                getInitialUserColumns = columns;
            }

            commit('SET_TABLE_COLUMNS', getInitialUserColumns);
            counter++;
        }
    },
    mutations: {
        SET_USERS(state, users) {
            state.users = users;
        },
        SET_FILTERS(state, filters) {
            state.filters = filters;
        },
        SET_TABLE_COLUMNS(state, data) {
            state.tableHeaders = data;
        },
        ADD_USER(state, data) {
            state.users.push(data);
        },

        UPDATE_USER(state, data) {
            const indexToUpdate = state.users.findIndex(entry => entry.businessId === data.businessId);
            if (indexToUpdate >= 0) {
                state.users[indexToUpdate] = data;
            }
        }
    }
};
