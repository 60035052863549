/* eslint-disable no-useless-escape */
import array from '@/util/array.js';

// Implementation of PHP's str_replace() which also works with arrays
function strReplace(search, replace, subject) {
    const f = [].concat(search);
    const r = [].concat(replace);
    let s = subject;
    const ra = Array.isArray(r);
    const sa = Array.isArray(s);

    s = [].concat(s);
    for (let i = 0, sl = s.length; i < sl; i++) {
        if (s[i] === '') {
            continue;
        }
        for (let j = 0, fl = f.length; j < fl; j++) {
            const temp = s[i] + '';
            let repl = r[0];
            if (ra) {
                repl = r[j] !== undefined ? r[j] : '';
            }
            s[i] = temp.split(f[j]).join(repl);
        }
    }
    return sa ? s : s[0];
}

export default {
    replace: strReplace,

    // Format a string with parameter placeholders; format('test {1} {0}', 'bar', 'foo') => 'test foo bar'
    format(str, ...args) {
        return str.replace(/{(\d+)}/g, (match, number) => {
            return typeof args[number] !== 'undefined' ? args[number] : match;
        });
    },

    // Removes all occurrences of the given needles from the haystack string; accepts either a string or an array as needles parameter
    remove(needles, haystack) {
        if (typeof needles === 'string') {
            return strReplace([needles], [''], haystack);
        }

        const replace = array.fill(needles.length, '');
        return strReplace(needles, replace, haystack);
    },

    // Converts the first letter of the given string to uppercase
    firstToUpper(str) {
        return str.charAt(0).toUpperCase() + str.slice(1);
    },

    // Converts the first letter of the given string to lowercase
    firstToLower(str) {
        return str.charAt(0).toLowerCase() + str.slice(1);
    },

    // Appends a unit to a value with correct pluralization, e.g. "42 minutes" but "1 minute"
    pluralize(value, unit) {
        const result = value + ' ' + unit;
        return value === 1 ? result : result + 's';
    },

    // Implementation of PHP's strip_tags which strips away all HTML tags from a string. keepTags should be a string with tags, e.g. '<i>' or '<i><b>'
    stripTags(str, keepTags = '') {
        const allowed = (keepTags.toLowerCase().match(/<[a-z][a-z0-9]*>/g) || []).join('');
        const tags = /<\/?([a-z][a-z0-9]*)\b[^>]*>/gi;
        return str.replace(/<!--[\s\S]*?-->/gi, '').replace(tags, ($0, $1) => {
            return allowed.indexOf('<' + $1.toLowerCase() + '>') > -1 ? $0 : '';
        });
    },

    // Escape special characters so the given string can be used to create a RegExp (see https://stackoverflow.com/a/3561711/1350193)
    escapeForRegExp(str) {
        const replacement = String.fromCharCode(92, 36, 38); // workaround for uglify-es which does not always work correctly when using a regular string here
        return str.replace(/[-\/\\^$*+?.()|[\]{}]/g, replacement);
    },

    // Create an RFC 4122 version 4 compliant UUID
    uuid() {
        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
            const r = (Math.random() * 16) | 0,
                v = c === 'x' ? r : (r & 0x3) | 0x8;
            return v.toString(16);
        });
    },

    // Get VIN7 from VIN17
    trimToVin7(longOrShortVin) {
        return longOrShortVin.substr(-7);
    }
};
