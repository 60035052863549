import { reactive } from "vue";
import { RentalPartnersApi } from "@/server/index";
import { COLUMNS } from "@/components/rentalPartners/config/tableColumns";
import TableColumnService from "@/components/util/paginatedTable/tableColumnService";
import RentalPartnerFactory from "@/components/rentalPartners/rentalPartnerFactory";

const initialState = {
  rentalPartners: [],
  filters: {},
  tableHeaders: [],
  promoParticipants: [],
};

let counter = 0;

export default {
  namespaced: true,
  state: reactive(initialState),
  getters: {
    rentalPartners: (state) => state.rentalPartners,
    promoParticipants: (state) => state.promoParticipants,
    rentalPartnersWithDealerId: (state) =>
      state.rentalPartners.filter((rentalPartner) =>
        Object.prototype.hasOwnProperty.call(rentalPartner, "dealerId")
      ),
    tableHeaders: (state) => state.tableHeaders,
    selectedTableHeaders: (state) =>
      state.tableHeaders.filter((header) => header.selected),
    tableFilters: (state) => state.filters,
    getRentalPartnerByBusinessId: (state) => (businessId) => {
      return state.rentalPartners.find(
        (rentalPartner) => rentalPartner.businessId === businessId
      );
    },
  },
  actions: {
    async getRentalPartners({ commit, dispatch }) {
      const { data } = await RentalPartnersApi.getRentalPartners();
      commit("SET_RENTAL_PARTNERS", data);
      dispatch("setFilters");
    },
    async getPromotionParticipants({ commit }, requestData) {
      const { data } = await RentalPartnersApi.getPromotionParticipants(
        requestData.promoId,
        requestData.countryCode
      );
      commit("SET_PROMO_PARTICIPANTS", data);
    },
    async createRentalPartner({ commit, dispatch }, rentalPartnerData) {
      const dataToSend =
        RentalPartnerFactory.prepareForSending(rentalPartnerData);
      const { data } = await RentalPartnersApi.createRentalPartner(dataToSend);
      commit("ADD_RENTAL_PARTNER", data);
      dispatch("setFilters");
    },
    async updateRentalPartner({ commit, dispatch }, rentalPartnerData) {
      const { businessId } = rentalPartnerData;
      const dataToSend =
        RentalPartnerFactory.prepareForSending(rentalPartnerData);
      await RentalPartnersApi.updateRentalPartner(businessId, dataToSend);
      commit("UPDATE_RENTAL_PARTNER", rentalPartnerData);
      dispatch("setFilters");
    },
    setFilters({ commit, state }) {
      const filters = TableColumnService.buildFiltersForTableData(
        COLUMNS,
        state.rentalPartners
      );
      commit("SET_FILTERS", filters);
    },
    addFilters({ commit, state }, filter) {
      commit("SET_FILTERS", {
        ...state.filters,
        ...filter,
      });
    },
    setTableColumns({ commit }, columns) {
      commit("SET_TABLE_COLUMNS", columns);
    },
  },
  mutations: {
    SET_RENTAL_PARTNERS(state, rentalPartners) {
      state.rentalPartners = rentalPartners;
    },
    SET_PROMO_PARTICIPANTS(state, promoParticipants) {
      state.promoParticipants = promoParticipants;
    },
    SET_FILTERS(state, filters) {
      state.filters = filters;
    },
    SET_TABLE_COLUMNS(state, data) {
      let getInitialRentalColumns;
      if (counter == 0) {
        getInitialRentalColumns = localStorage.getItem("rental-partner-columns")
          ? JSON.parse(localStorage.getItem("rental-partner-columns"))
          : data;
      } else {
        getInitialRentalColumns = data;
      }
      state.tableHeaders = getInitialRentalColumns;
      counter++;
    },
    ADD_RENTAL_PARTNER(state, data) {
      state.rentalPartners.push(data);
    },
    UPDATE_RENTAL_PARTNER(state, data) {
      const indexToUpdate = state.rentalPartners.findIndex(
        (entry) => entry.businessId === data.businessId
      );
      state.rentalPartners[indexToUpdate] = data;
    },
  },
};
