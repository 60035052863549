import { reactive } from 'vue';
import { DmsCertificatesApi } from '@/server/index';
import { COLUMNS } from '@/components/dmsCertificates/config/tableColumns';
import TableColumnService from '@/components/util/paginatedTable/tableColumnService';

const initialState = {
    dmsCertificates: [],
    filters: {},
    tableHeaders: []
};

export default {
    namespaced: true,
    state: () => reactive(initialState),
    getters: {
        dmsCertificates: state => state.dmsCertificates,
        tableHeaders: state => state.tableHeaders,
        selectedTableHeaders: state => state.tableHeaders.filter(header => header.selected),
        tableFilters: state => state.filters
    },
    actions: {
        async getDmsCertificates({ commit, dispatch }) {
            const { data } = await DmsCertificatesApi.getDmsCertificates();
            commit('SET_DMS_CERTIFICATES', data);

            dispatch('setFilters');
        },

        async createDMSCertificate({ commit, dispatch }, certificateData) {
            const requestData = {
                buno: certificateData.dealerId,
                notificationEmails: certificateData.notificationEmails,
                commonName: certificateData.name
            };
            await DmsCertificatesApi.createDmsCertificate(requestData);
            commit('ADD_CERTIFICATE', certificateData);

            dispatch('setFilters');
        },

        async updateDmsCertificateNotificationEmails({ commit, dispatch }, dmsCertificateData) {
            const requestData = {
                rentalPartnerBusinessId: dmsCertificateData.rentalPartnerBusinessId,
                notificationEmails: dmsCertificateData.notificationEmails,
                commonName: dmsCertificateData.name
            };
            await DmsCertificatesApi.updateDmsCertificate(requestData);
            commit('UPDATE_DMS_CERTIFICATE', dmsCertificateData);
            dispatch('setFilters');
        },

        setFilters({ commit, state }) {
            const filters = TableColumnService.buildFiltersForTableData(COLUMNS, state.dmsCertificates);
            commit('SET_FILTERS', filters);
        },

        addFilter({ commit, state }, filter) {
            commit('SET_FILTERS', {
                ...state.filters,
                ...filter
            });
        },

        setTableColumns({ commit }, columns) {
            commit('SET_TABLE_COLUMNS', columns);
        },

        async downloadCertificate({ state }, certificateData) {
            try {
                DmsCertificatesApi.downloadCertificate(
                    certificateData.rentalPartnerBusinessId,
                    certificateData.name
                ).then(response => {
                    const url = window.URL.createObjectURL(new Blob([response.data]));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', 'certificate' + '.zip');
                    document.body.appendChild(link);
                    link.click();
                });
            } catch (error) {
                console.error('Error downloading zip file:', error);
            }
        },
        async deleteCertificate({ commit }, dmsCertificate) {
            await DmsCertificatesApi.deleteCertificate(dmsCertificate.rentalPartnerBusinessId, dmsCertificate.name);
            commit('DELETE_CERTIFICATE', dmsCertificate);
        }
    },
    mutations: {
        SET_DMS_CERTIFICATES(state, dmsCertificates) {
            state.dmsCertificates = dmsCertificates;
        },
        SET_FILTERS(state, filters) {
            state.filters = filters;
        },
        ADD_CERTIFICATE(state, data) {
            state.dmsCertificates.push(data);
        },
        SET_TABLE_COLUMNS(state, data) {
            state.tableHeaders = data;
        },
        UPDATE_DMS_CERTIFICATE(state, data) {
            const indexToUpdate = state.dmsCertificates.findIndex(entry => entry.businessId === data.businessId);
            if (indexToUpdate >= 0) {
                state.dmsCertificates[indexToUpdate] = data;
            }
        },
        DELETE_CERTIFICATE(state, data) {
            let index;
            if (data.rentalPartnerBusinessId) {
                index = state.dmsCertificates.findIndex(
                    entry => entry.rentalPartnerBusinessId === data.rentalPartnerBusinessId
                );
            } else {
                index = state.dmsCertificates.findIndex(entry => entry.name === data.name);
            }
            if (index >= 0) {
                state.dmsCertificates.splice(index, 1);
            }
        }
    }
};
