import { COLUMN_TYPE } from '@/components/util/paginatedTable/columnTypes';

export class FilterEntry {
    constructor() {
        this.model = '';
        this.type = COLUMN_TYPE.arbitrary;
        this.columnName = '';
    }

    doesMatch(value) {
        switch (this.type) {
            case COLUMN_TYPE.arbitrary:
                return ('' + value).toLowerCase().includes(('' + this.model).toLowerCase());

            case COLUMN_TYPE.arbitraryEnum:
                if ((this.model || this.model.length) && this.model.split(',').indexOf('promoIds') >= 0) {
                    let arrayModel = this.model.split(',');
                    arrayModel.shift();
                    arrayModel.forEach(item => {
                        if (`${value}` === `${item}`) {
                            return true;
                        }
                        return false;
                    });
                }

                return !this.model || this.model.length === 0
                    ? true
                    : this.model
                          .split(',')
                          .some(singleModel => ('' + value).toLowerCase().includes(('' + singleModel).toLowerCase()));

            // eslint-disable-next-line no-fallthrough
            case COLUMN_TYPE.enum:
                return this.model
                    .filter(e => e.selected)
                    .map(e => (e.item == 'BLANK' ? '' : e.item))
                    .includes(String(value));

            case COLUMN_TYPE.dateRange:
                if (
                    !this.model.startDate ||
                    !this.model.endDate ||
                    (value >= this.model.startDate && value <= this.model.endDate)
                ){
                    return true;
                }
                return false;
        }

        console.error(`Missing type ${this.type}`); // eslint-disable-line

        return false;
    }

    isActive() {
        if (this.type === COLUMN_TYPE.arbitrary) {
            return this.model.length > 0;
        }

        if (this.type === COLUMN_TYPE.arbitraryEnum) {
            return this.model.length > 0;
        }

        if (this.type === COLUMN_TYPE.enum) {
            const selectedEntriesLength = this.model.filter(entry => entry.selected).length;
            return this.model.length !== selectedEntriesLength;
        }

        if (this.type === COLUMN_TYPE.dateRange) {
            // dateRange filter will only activate when both start and end date are selected
            return this.model.startDate.length > 0 && this.model.endDate.length > 0;
        }

        return false;
    }
}
