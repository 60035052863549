<template>
    <div class="appNotifications">
        <div v-show="messages.length >= 2">
            <button class="btn btn-default btn-xs hideAllButton" @click="hideAllMessages">
                <i class="fa fa-remove"></i> Hide all messages
            </button>
        </div>

        <div v-for="(message, index) in messages" :key="index">
            <div :class="['alert', message.type]">
                <table>
                    <tr>
                        <th id=""></th>
                    </tr>
                    <tr>
                        <td>
                            <i :class="['fa', icons[message.type]]"></i>
                        </td>
                        <td>
                            <span v-html="message.message"></span>
                            <template v-if="message.info">
                                <br />
                                <span class="infoLink" @click.prevent="openErrorWindow(message.info)">
                                    Open error details and copy content into support ticket
                                </span>
                            </template>
                        </td>
                        <td>
                            <button class="close" @click="hideMessage(message.id)">&times;</button>
                        </td>
                    </tr>
                </table>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
    data() {
        return {
            icons: {
                error: 'fa-exclamation-circle',
                warning: 'fa-warning',
                success: 'fa-check-circle',
                info: 'fa-info-circle'
            }
        };
    },

    computed: {
        ...mapGetters('notifications', ['messages'])
    },

    methods: {
        hideMessage(id) {
            this.$store.dispatch('notifications/hideMessage', id);
        },

        hideAllMessages() {
            this.$store.dispatch('notifications/hideAllMessages');
        },

        openErrorWindow(errorInfo) {
            const errorInfoStr = JSON.stringify(errorInfo);
            const win = window.open('', '_blank');
            win.document.title = 'ODM Cockpit Debug Details';

            const preElement = win.document.createElement('pre');
            preElement.appendChild(win.document.createTextNode(errorInfoStr));
            win.document.body.appendChild(preElement);
        }
    }
};
</script>

<style lang="scss">
@import '~@/assets/styles/settings';
@mixin notificationColor($background, $border, $color) {
    background-color: $background;
    border-color: $border;
    color: $color;

    .infoLink {
        color: darken($color, 15);
        text-decoration: underline;
        cursor: pointer;

        &:hover {
            color: darken($color, 40);
        }
    }
}

.appNotifications {
    position: fixed;
    bottom: 40px;
    z-index: $z-index-legacy-modal + 1;
    margin: 0 30px;

    .alert {
        margin: 15px 0 0;
        box-shadow: 0 0 10px transparentBlack(0.17);
        display: inline-block;
        width: auto;
        max-width: 900px;
        padding: 15px;
        padding-right: 35px;
        border: 1px solid transparent;
        border-radius: 0px !important;
        box-sizing: border-box;

        &.error {
            @include notificationColor($bs-color-error-bg, $bs-color-error-border, $bs-color-error-text);
        }
        &.warning {
            @include notificationColor($bs-color-warning-bg, $bs-color-warning-border, $bs-color-warning-text);
        }
        &.success {
            @include notificationColor($bs-color-success-bg, $bs-color-success-border, $bs-color-success-text);
        }
        &.info {
            @include notificationColor($bs-color-info-bg, $bs-color-info-border, $bs-color-info-text);
        }

        .fa {
            margin-right: 10px;
        }

        .close {
            margin-left: 15px;
            position: relative;
            top: -2px;
            color: inherit;
            padding: 0;
            background: transparent;
            border: 0;
            cursor: pointer;
            float: right;
            font-size: 21px;
            line-height: 1;
            opacity: 0.2;

            &:hover {
                color: $color_black;
                opacity: 0.5;
            }
        }
    }

    .hideAllButton {
        margin-bottom: 5px;
        padding: 1px 5px;
        font-size: 12px;
        line-height: 1.5;
        border-radius: 0px !important;
        color: $color_gray_nero;
        background-color: $color_white;
        display: inline-block;
        text-align: center;
        vertical-align: middle;
        cursor: pointer;
        border: 1px solid $color_bootstrap_btn_border;
        white-space: nowrap;
        user-select: none;

        &:hover {
            background-color: $color_bootstrap_btn_bg;
            border-color: $color_bootstrap_table_border;
        }
    }
}
</style>
