import BuildVariables from '@/util/buildVariables';

let hostname = typeof self !== 'undefined' ? self.location.hostname : '';

export default {
    getEnvironment() {
        if (hostname === 'localhost' || hostname === '127.0.0.1') {
            return 'TEST';
        }

        const domainParts = hostname.split('.');
        if (domainParts[0] === 'rentaride-cockpit') {
            const prefix = domainParts[1].split('-').shift();
            switch (prefix) {
                case 'test':
                    return 'TEST';
                case 'local':
                    return 'TEST';
                case 'int':
                    return 'INT';
                default:
                    return 'PROD';
            }
        }

        return 'TEST';
    },

    isLocal() {
        const hostname1 = window.location.hostname;
        return !BuildVariables.isProductionEnv() || hostname1 === 'localhost' || hostname1 === '127.0.0.1';
    }
};
