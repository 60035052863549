export let moment;
export let $;

export async function importMoment() {
    if (!moment) {
        moment = await import(/* webpackChunkName: "moment" */ 'moment');
        const momentTimezone = await import(/* webpackChunkName: "moment" */ 'moment-timezone');
        moment.tz = momentTimezone.default.tz;
    }
}

export async function importBootstrap() {
    if (!$) {
        const bootstrapExports = await import(/* webpackChunkName: "bootstrap" */ '@/assets/vendor/bootstrap');
        $ = bootstrapExports.$;
    }
}
