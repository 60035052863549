<template>
  <div v-if="visible" class="popup-container" ref="otpModal">
    <div class="popup-content">
      <h2>Forgot Password</h2>
      <div>
        Please enter your username below to receive an e-mail for the password
        reset.
      </div>
      <div>
        Please note that the generated one-time password is valid for just 10
        minutes.
      </div>
      <form>
        <div class="form-group">
          <input
            type="text"
            id="username"
            v-model="username"
            placeholder="User Name*"
            required
          />
        </div>
        <div style="display: flex; justify-content: space-between">
          <button type="button" class="btn button-sec" @click="cancel">
            Cancel
          </button>
          <button
            type="button"
            class="btn button-pr"
            @click="sendOneTimePassword"
          >
            Send
          </button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import Notifications from "@/util/notifications";

export default {
  name: "ForgotPassModal",
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      username: "",
    };
  },
  methods: {
    ...mapActions("login", ["resetPassword"]),
    async sendOneTimePassword() {
      await this.resetPassword(this.username);
      this.$emit("close");
      Notifications.showSuccess(
        `OTP sent successfully on the registered email address.`,
        true,
        false
      );
    },
    cancel() {
      this.$emit("close");
    },
  },
};
</script>

<style scoped>
.popup-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.popup-content {
  background-color: #fff;
  padding: 30px;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}

h2 {
  padding-bottom: 20px;
}

.form-group {
  padding-top: 15px;
}

input {
  width: 100%;
  padding: 10px;
  background-color: #e8f0fe;
  border: none;
  box-sizing: border-box;
}

.button-pr {
  background-color: #16171a !important;
  border-color: #16171a !important;
  color: #fff !important;
  border-radius: 0 !important;
  font-weight: 700 !important;
  opacity: 1 !important;
}

.button-sec {
  background-color: #fff !important;
  border-color: #16171a !important;
  color: #16171a !important;
  border-radius: 0 !important;
  font-weight: 700 !important;
  opacity: 1 !important;
}

.button-sec:hover,
.button-pr:hover {
  background-color: #0062ff !important;
  border-color: #0062ff !important;
  color: #fff !important;
  text-decoration: none;
}

.btn.button-pr:disabled {
  opacity: 0.5 !important;
  cursor: not-allowed;
}

input:focus {
  border: 3px solid #bfdeff !important;
}
</style>
