<template>
  <div class="tableHeaderFilter">
    <i
      class="fa fa-filter filterIcon"
      :class="{ 'icon-white': !filter.isActive() }"
      @click="openFilterDropdown"
    ></i>
    <div class="filterDropdown" :class="{ dropdownOpen }" ref="filterDropdown">
            <component :is="getFilterComponent(filter.type)" v-model="filter.model"></component>
        </div>
  </div>
</template>

<script>
import TableHeaderSearchFilter from "@/components/util/paginatedTable/filters/TableHeaderSearchFilter";
import TableHeaderCheckboxFilter from "@/components/util/paginatedTable/filters/TableHeaderCheckboxFilter";
import { COLUMN_TYPE } from "@/components/util/paginatedTable/columnTypes";
import TableHeaderDateFilter from "@/components/util/paginatedTable/filters/TableHeaderDateFilter";
import TableHeaderDateRangeFilter from "@/components/util/paginatedTable/filters/TableHeaderDateRangeFilter";

export default {
  name: "TableHeaderFilter",

  props: {
    columnKey: {
      required: true,
      type: String,
    },
    filter: {
      required: true,
      type: Object,
    },
  },

  data() {
    return {
      dropdownOpen: false,
    };
  },

  methods: {
    openFilterDropdown() {
      this.dropdownOpen = !this.dropdownOpen;
      if (this.dropdownOpen) {
        setTimeout(
          () => window.addEventListener("click", this.closeFilterDropdown),
          0
        );
      }
    },

    closeFilterDropdown(event) {
      if (
        this.$refs.filterDropdown &&
        this.$refs.filterDropdown.contains(event.target)
      ) {
        return;
      }
      this.dropdownOpen = false;
      window.removeEventListener("click", this.closeFilterDropdown);
    },

    getFilterComponent(type) {
      switch (type) {
        case COLUMN_TYPE.arbitrary:
          return TableHeaderSearchFilter;
        case COLUMN_TYPE.arbitraryEnum:
          return TableHeaderSearchFilter;
        case COLUMN_TYPE.arbitraryEqual:
          return TableHeaderSearchFilter;
        case COLUMN_TYPE.enum:
          return TableHeaderCheckboxFilter;
        case COLUMN_TYPE.date:
          return TableHeaderDateFilter;
        case COLUMN_TYPE.dateRange:
          return TableHeaderDateRangeFilter;
      }

      throw "Cannot get filter component for unsupported type: " + type;
    },
  },
};
</script>

<style lang="scss">
@import "~@/assets/styles/settings";

.tableHeaderFilter {
  // This is very important when it comes to scrolling the overflow container (i.e. the table).
  // It ensure that the absolutely positioned child element scrolls with the rest of the content.
  position: relative;

  .filterIcon {
    margin-top: 3px;
    margin-right: 5px;

    &:hover {
      color: $color_gray;
      cursor: pointer;
    }
    @mixin iconTextShadow($color) {
      text-shadow:
        -1px 0 $color,
        0 1px $color,
        2px 0 $color,
        0 -1px $color;
    }
    &.icon-white {
      color: $color_white;
      @include iconTextShadow($color_black);
    }
  }

  .filterDropdown {
    display: none;
    position: absolute;
    background: $color_white;
    padding: 10px;
    margin-top: 9px;
    box-shadow: 0 0 15px transparentBlack(0.2);
    font-weight: normal;

    &.dropdownOpen {
      display: block;
    }
  }
}
</style>
