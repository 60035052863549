<template>
    <tr class="fieldsRow">
        <!-- <td class="fieldName">
            <slot name="name"></slot>
        </td> -->

        <td>
            <slot name="content"></slot>
            <span class="hint text-muted" v-if="$slots.hint">
                <slot name="hint"></slot>
            </span>
        </td>
    </tr>
</template>

<script>
export default {
    name: 'FieldsRow'
};
</script>

<style lang="scss">
@import '~@/assets/styles/settings';

.fieldsRow {
    > td {
        padding-top: 15px;

        &:first-child {
            padding-top: 23px;
        }
    }

    .fieldName {
        width: 30%;
        font-weight: bold;
        vertical-align: top;
        padding-right: 15px;

        .description {
            margin-top: 0.25em;
            font-size: 0.8em;
            font-weight: initial;
            color: $color_gray;
        }
    }

    .hint {
        font-size: 84%;
        display: inline-block;
        margin-top: 4px;
    }
}
</style>
