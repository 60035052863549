import $store from '@/store';

async function showMessage(type, message, hideAfterTimeout = false, uniqueMessageText = false, info = undefined) {
    return await $store.dispatch('notifications/showMessage', {
        type,
        message,
        hideAfterTimeout,
        uniqueMessageText,
        info
    });
}

export default {
    showError: showMessage.bind(null, 'error'),
    showWarning: showMessage.bind(null, 'warning'),
    showSuccess: showMessage.bind(null, 'success'),
    showInfo: showMessage.bind(null, 'info'),

    async hide(idPromise) {
        const id = await idPromise;
        $store.dispatch('notifications/hideMessage', id);
    },

    hideAll() {
        $store.dispatch('notifications/hideAllMessages');
    }
};
