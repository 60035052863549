<template>
  <br />
  <AppNavigation v-if="isAuthenticated" />
  <br />
  <br />
  <div id="app" class="bootstrap-root">
    <app-notifications></app-notifications>
    <div class="container-fluid">
      <transition name="fade">
        <router-view />
      </transition>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import AppNotifications from "@/components/core/AppNotifications";
import AppNavigation from "@/components/core/AppNavigation";
import store from "@/store";

export default {
  name: "App",
  mounted() {
    const token = localStorage.getItem("idToken");
    const cockpitUserInfo = localStorage.getItem("cockpitUserInfo");
    if (token && cockpitUserInfo) {
      this.initializeAuthentication().then(() => {
        this.callingInitialApiForData();
      });
    }
  },

  components: {
    AppNavigation,
    AppNotifications,
  },
  computed: {
    ...mapState("login", ["isAuthenticated"]),
  },
  methods: {
    ...mapActions("login", ["initializeAuthentication"]),
    async callingInitialApiForData() {
      if (this.isAuthenticated) {
        try {
          await store.dispatch("permissions/loadPermissions");

          await Promise.all([
            store.dispatch("rentalPartners/getRentalPartners"),
            store.dispatch("users/getUsers"),
            store.dispatch("promotions/getPromotions"),
            store.dispatch("promotions/getPromotionsValues"),
          ]);
          if (localStorage.getItem("cockpitUserInfo")) {
            store.dispatch("cockpitUsers/getUsers");
          }
        } catch {
          this.$router.push("/login");
        }
      }
    },
  },
};
</script>
