<template>
  <div class="userEditForm">
    Fields marked with an asterisk * are required.

    <table class="fieldsTable">
      <tr>
        <th id=""></th>
      </tr>
      <fields-row>
        <template v-slot:name>
          <span>Username*</span>
        </template>
        <template v-slot:content>
          <input
            type="text"
            class="form-control"
            placeholder="Username"
            v-validate="validations.username"
            v-model="formData.username"
            @keydown.space.prevent
            v-on:keypress="isLetterOrNumber($event)"
          />
        </template>
      </fields-row>

      <fields-row>
        <template v-slot:name>
          <span>E-Mail*</span>
        </template>
        <template v-slot:content>
          <input
            type="text"
            class="form-control"
            placeholder="E-Mail"
            v-validate="validations.email"
            v-model="formData.email"
          />
        </template>
      </fields-row>

      <fields-row>
        <template v-slot:name>
          <span>User Group Type*</span>
        </template>

        <template v-slot:content>
          <div class="row">
            <div class="col-xs-6">
              <select
                class="form-control"
                v-validate="validations.userGroupType"
                v-model="formData.userGroupType"
              >
                <option value="" disabled>User Group Type*</option>
                <option
                  v-for="(label, key) in availablecockpituserTypes"
                  :key="key"
                  :value="key"
                >
                  {{ label }}
                </option>
              </select>
            </div>
          </div>
        </template>
      </fields-row>

      <fields-row>
        <template v-slot:name>
          <span>Enabled*</span>
        </template>

        <template v-slot:content>
          <div
            class="checkboxContainer"
            style="display: flex; align-items: center"
          >
            <input
              type="checkbox"
              class="custom-checkbox"
              v-model="formData.enabled"
            />
            <label class="inputLabel">Enabled</label>
          </div>
        </template>
      </fields-row>
    </table>
  </div>
</template>

<script>
/* eslint no-control-regex: 0 */
import FieldsRow from "@/components/util/FieldsRow";
import validityChecked from "@/components/util/mixins/validityChecked";
import CockpitUserFactory from "@/components/cockpitUsers/CockpitUserFactory";

import { LANGUAGE_CODES } from "@/util/languages";
// import { mapGetters } from 'vuex';

const { GENDERS, USER_TYPES, COCKPIT_USER_TYPES } =
  CockpitUserFactory.getOptions();

function isValidEmail(email) {
  var re =
    /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/;
  return re.test(String(email).toLowerCase());
}

function isValidUsername(username) {
  if (/[^A-Za-z0-9_.-]/.test(username)) {
    return false;
  } else {
    return true;
  }
}

export default {
  name: "CockpitUserEditForm",

  components: {
    FieldsRow,
  },

  mixins: [validityChecked],

  props: {
    formData: {
      type: Object,
      required: true,
    },
    isUpdating: {
      type: Boolean,
      required: true,
    },
  },

  data() {
    return {
      availableGenders: GENDERS,
      availableuserTypes: USER_TYPES,
      availablecockpituserTypes: COCKPIT_USER_TYPES,
      availableLanguageCodes: LANGUAGE_CODES,
      validations: {
        username: {
          required: true,
          custom: () => {
            return isValidUsername(this.formData.username);
          },
          isValid: false,
        },
        email: {
          required: true,
          custom: () => {
            return isValidEmail(this.formData.email);
          },
          isValid: false,
        },
        userGroupType: {
          required: true,
          custom: () => {
            return Object.keys(COCKPIT_USER_TYPES).includes(
              this.formData.userGroupType
            );
          },
          isValid: false,
        },
      },
    };
  },
  methods: {
    isLetterOrNumber: function (e) {
      let char = String.fromCharCode(e.keyCode);
      if (/^[a-zA-Z0-9_.-]+$/.test(char)) {
        return true;
      } else e.preventDefault();
    },
  },
  computed: {
    // ...mapGetters('rentalPartners', ['rentalPartnersWithDealerId']),

    availableRentalPartners() {
      const alphaNumericDealers = {};
      this.rentalPartnersWithDealerId.reduce((agg, item) => {
        if (item.dealerId != undefined || item.dealerId != "") {
          alphaNumericDealers[item.businessId] = item.dealerId;
        }
      }, {});
      const sortedAlphaNumericDealers = Object.entries(
        alphaNumericDealers
      ).sort(function (a, b) {
        let firstValue = /^[0-9]+$/.test(a[1]) ? a[1] : a[1].toLowerCase();
        let secondValue = /^[0-9]+$/.test(b[1]) ? b[1] : b[1].toLowerCase();

        if (firstValue < secondValue) {
          return -1;
        } else if (firstValue > secondValue) {
          return 1;
        }
        return 0;
      });

      return Object.fromEntries(sortedAlphaNumericDealers);
    },
    showReasonForDisabled() {
      return this.isUpdating && !this.formData.enabled;
    },
  },
};
</script>

<style scoped lang="scss">
@import "~@/assets/styles/components/_form";

.userEditForm {
  @include entityEditForm();
}

.form-control {
  border-radius: 0 !important;
  border: none !important;
  background-color: #f2f2f2 !important;
  font-size: 16px !important;
  font-weight: 300 !important;
  height: 50px !important;
  line-height: 26px !important;
  box-shadow: none !important;
}

input:focus {
  border: 3px solid #bfdeff !important;
}

.custom-checkbox {
  width: 30px;
  height: 30px;

  --checkbox-color: #0062ff;

  border: 2px solid var(--checkbox-color);
  border-radius: 0px;
  outline: none;
}

.custom-checkbox:checked {
  background-color: var(--checkbox-color);
}

.inputLabel {
  color: #aaaaaa !important;
  margin-left: 10px !important;
  font-weight: 500 !important;
  margin-bottom: 0px !important;
}
</style>
