import Util from '@/util';

const COCKPIT_USER_TYPES = {
    COCKPIT_ADMIN: 'Cockpit Full Admin',
    COCKPIT_USER_MANAGE: 'Cockpit User Manage',
    COCKPIT_USER_VIEW: 'Cockpit User View',
};

const DEFAULT_USER = {
    cockpitUserBusinessId: '',
    username: '',
    email: '',
    enabled: true,
    userGroupType: '',
};

const OPTIONS = {
    COCKPIT_USER_TYPES
};

function create(withData = {}) {
    return {
        ...DEFAULT_USER,
        ...withData
    };
}

function prepareForSending(configToSend) {
    return Util.object.clone(configToSend);
}

export default {
    create,
    prepareForSending,

    getOptions: () => OPTIONS
};
