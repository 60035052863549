import Authorization from '@/util/authorization';
import { reactive } from 'vue';

export default {
  namespaced: true,

  state: reactive({
    permissions: [],
    token: null
  }),

  getters: {
    permissions: state => state.permissions,
    token: state => state.token,
    hasRentalPartnerManagePermission: state => state.permissions.includes('rentalpartner_manage'),
    hasUserManagePermission: state => state.permissions.includes('rentalpartner_account_manage'),
    hasPromotionManagePermission: state => state.permissions.includes('rar_promotions_manage'),
    hasRentalPartnerReadPermission: state => state.permissions.includes('rentalpartner_account_read')
  },

  actions: {
    async loadPermissions({ commit }) {
      let permission, token;
      try {
        [token, permission] = await Promise.all([Authorization.getRawToken(), Authorization.getPermission()]);
      } catch (e) {
        console.error(e);
      }
      commit('SET_PERMISSIONS', permission);
      commit('SET_TOKEN', token);
    }
  },

  mutations: {
    SET_PERMISSIONS(state, permissions) {
      state.permissions = permissions;
    },
    SET_TOKEN(state, token) {
      state.token = token;
    }
  }
};
