import ajax from '@/util/ajax';
import api from '@/config/api';

const BASE_URL = api.getServiceUrl('catalogueService');
const BASE_PROMO_URL = api.getServiceUrl('promotionService');
const API_PATH = '/api/cockpit';

function getUrl() {
    return BASE_URL + API_PATH;
}

function getPromoUrl() {
    return BASE_PROMO_URL + API_PATH;
}

export default {
    getRentalPartners() {
        const url = `${getUrl()}/rental-partners`;
        return ajax.get(url);
    },
    createRentalPartner(data) {
        const url = `${getUrl()}/rental-partners`;
        return ajax.post(url, data);
    },
    updateRentalPartner(businessId, data) {
        const url = `${getUrl()}/rental-partners/${businessId}`;
        return ajax.post(url, data);
    },
    getPromotionParticipants(promoId, countryCode) {
        const url = `${getPromoUrl()}/promotions/participant-details/${promoId}/${countryCode}`;
        return ajax.get(url);
    }
};
