<template>
    <div class="tableHeaderDateFilter input-group">
        <input type="date" :max="maxDate" :min="minDate" class="form-control" placeholder="Search" v-model="model" />
        <span class="input-group-addon">
            <i class="fa fa-times" @click="clear()" aria-hidden="true"></i>
        </span>
    </div>
</template>

<script>
export default {
    name: 'TableHeaderDateFilter',

    model: {
        prop: 'outerModel'
    },

    props: {
        outerModel: {
            type: String,
            required: true
        }
    },

    computed: {
        model: {
            // Sync inner model with outer model
            get() {
                return this.outerModel;
            },
            set(value) {
                this.$emit('input', value);
                this.$eventBus.$emit('selection-changed-filter', value);
            }
        },
        minDate() {
            let year = new Date().getFullYear();
            return `${year}-01-01`;
        },
        maxDate() {
            let year = new Date().getFullYear();
            return `${year}-12-31`;
        }
    },

    methods: {
        clear: function() {
            this.model = '';
        }
    }
};
</script>

<style lang="scss">
.tableHeaderDateFilter {
    width: 150px;
}
</style>
